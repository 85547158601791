import {gql, useApolloClient, useMutation} from '@apollo/client'
import {GET_ACTIVE_ORDER, ORDER_FRAGMENT, useActiveOrder} from './useOrder'
import {AddItemToOrder, AddItemToOrderVariables} from './types/AddItemToOrder'
import useTransitionOrderToState from './useTransitionOrderToState'
import {getActiveOrder} from './types/getActiveOrder'
import {useCallback} from 'react'

export const ADD_ITEM_TO_ORDER = gql`
  mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {
    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
      __typename
      ... on Order {
        ...OrderFragment
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${ORDER_FRAGMENT}
`
export const useAddItemToOrder = () => {
  const activeOrder = useActiveOrder()
  const [transitionOrderToState] = useTransitionOrderToState()
  const apolloClient = useApolloClient()
  const [addItemToOrderMutation] = useMutation<
    AddItemToOrder,
    AddItemToOrderVariables
  >(ADD_ITEM_TO_ORDER, {
    refetchQueries: [GET_ACTIVE_ORDER],
  })

  return useCallback(
    async (productVariantId: string, quantity: number) => {
      try {
        if (activeOrder?.state !== 'AddingItems') {
          await transitionOrderToState({
            variables: {
              OrderStates: 'AddingItems',
            },
          })
        }
        // FIXME: use built-in mechanism of mutation to update cache
        apolloClient.cache.updateQuery(
          {query: GET_ACTIVE_ORDER},
          (data: getActiveOrder | null) =>
            data
              ? {
                  ...data,
                  activeOrder: data.activeOrder
                    ? {
                        ...data.activeOrder,
                        lines: data.activeOrder.lines.map((el) =>
                          el.productVariant.id === productVariantId
                            ? {...el, quantity: el.quantity + quantity}
                            : el,
                        ),
                      }
                    : null,
                }
              : null,
        )

        await addItemToOrderMutation({
          variables: {productVariantId, quantity},
        })
      } catch (e) {
        // TODO error handling
        console.error(e)
      }
    },
    [
      activeOrder?.state,
      apolloClient?.cache,
      addItemToOrderMutation,
      transitionOrderToState,
    ],
  )
}
