import { Icon } from '@ui-kitten/components'
import React, { FC } from 'react'
import { ImageStyle, StyleProp, StyleSheet, ViewStyle } from 'react-native'

const MyIcon: FC<IMyIcon> = React.memo(({ fill, style, name }) => {
  return (
    <Icon
      fill={fill}
      style={[styles.icon, style]}
      animation="shake"
      name={name}
    />
  )
})

export default MyIcon

const styles = StyleSheet.create({
  icon: {
    width: 28,
    height: 28,
  },
})

interface IMyIcon {
  style?: ViewStyle | StyleProp<ImageStyle>
  fill: string
  name: string
}
