import {Modal, Pressable, Text, View} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import Button from '../../ui/primitive/components/Button'
import React, {FC, useState} from 'react'
import LottieView from 'lottie-react-native'

const WaitingScreen: FC<IWaitingScreen> = ({
  placeOrder,
  goBack,
  afterWait,
  visible,
}) => {
  const styles = useStyleSheet(themedStyles)
  const [isWait, setIsWaiting] = useState(false)

  const placeHandler = () => {
    setIsWaiting(true)
    placeOrder()
      .then(() => {
        setIsWaiting(false)
        afterWait()
      })
      .catch((e) => {
        console.log('error in orderConfirm', e)
      })
  }

  return (
    <Modal visible={visible} animationType={'slide'}>
      <View style={styles.wrapper}>
        <View style={styles.backdrop} />
        <View style={styles.card}>
          {!isWait ? (
            <>
              <Text style={styles.header}>Order Now</Text>
              <Text style={styles.subHeader}>
                By placing your order it will become definitive and we will
                start preparing it. Are you sure you want to order?
              </Text>
              <Button
                type={'blue'}
                style={styles.button}
                onPress={placeHandler}
              >
                <Text style={styles.buttonText}>Place Order</Text>
              </Button>
              <Pressable onPress={goBack}>
                <Text style={styles.backText}>Go Back</Text>
              </Pressable>
            </>
          ) : (
            <>
              <LottieView
                source={require('./../../assets/animations/wait_animation.json')}
                autoPlay
                loop
              />
              <Text style={styles.subHeader}>
                Please don’t close this screen and wait for your order to be
                confirmed
              </Text>
            </>
          )}
        </View>
      </View>
    </Modal>
  )
}

export default WaitingScreen

export interface IWaitingScreen {
  placeOrder: () => Promise<any>
  goBack: () => void
  afterWait: () => void
  visible: boolean
}

const themedStyles = StyleService.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 20,
  },
  backdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: '#999999',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  card: {
    width: '80%',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 24,
    display: 'flex',
  },
  header: {
    color: '#333333',
    textTransform: 'uppercase',
    fontSize: 28,
    fontFamily: 'oswald-bold',
    marginBottom: 12,
  },
  subHeader: {
    color: '#333333',
    fontFamily: 'inter-regular',
    fontSize: 14,
    marginBottom: 32,
  },
  button: {
    height: 48,
    width: '100%',
    borderRadius: 28,
    marginBottom: 16,
  },
  buttonText: {
    color: '#FFFFFF',
    fontFamily: 'inter-bold',
    fontSize: 12,
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
  backText: {
    color: '#333333',
    fontFamily: 'inter-regular',
    fontSize: 12,
    letterSpacing: 2,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    marginHorizontal: 'auto',
  },
})
