import React, {FC, useEffect, useState, useRef} from 'react'
import {StyleSheet, ScrollView, View, Dimensions} from 'react-native'
import {intervalCounter} from '../../utils/intervalCounter'
import Head from './Head'
import SubHead from './SubHead'

const {height: deviseHeight} = Dimensions.get('window')

const isMobile = 'ontouchend' in document

const ScrollWithHeader: FC<IScrollWithHeader> = ({children, topIndent}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [headerHeight] = useState(46)
  const [subHeaderHeight, setSubHeaderHeight] = useState(103)
  const [indent, setIndent] = useState(103)
  const [animationInProgress, setAnimationInProgress] = useState(false)
  const headerContainerRef = useRef(null)
  const headerRef = useRef(null)
  const subheaderRef = useRef(null)
  const scrollWrapper = useRef(null)

  useEffect(() => {
    if (scrollWrapper && scrollWrapper.current) {
      const {scrollHeight = 0, clientHeight = 0} =
        scrollWrapper.current.getScrollableNode()
      if (scrollHeight <= clientHeight) {
        setIsCollapsed(false)
      }
    }
  })

  useEffect(() => {
    if (subheaderRef.current?.clientHeight) {
      setTimeout(() => {
        setSubHeaderHeight(subheaderRef.current?.clientHeight)
        setIndent(subheaderRef.current?.clientHeight)
      }, 1000)
    }
  }, [subheaderRef.current?.clientHeight])

  useEffect(() => {
    if (!isMobile || animationInProgress) return
    setAnimationInProgress(true)
    if (isCollapsed) {
      intervalCounter(subHeaderHeight, 0, setIndent, animationState)
    } else {
      intervalCounter(0, subHeaderHeight, setIndent, animationState)
    }
  }, [isCollapsed])

  const animationState = () => {
    setAnimationInProgress(false)
  }

  const onScroll = (event) => {
    if (event.nativeEvent.contentOffset.y >= 40) {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
  }
  // if (!subheaderRef.current?.clientHeight) return null

  return (
    <View style={styles.wrapper}>
      <View style={styles.header} ref={headerContainerRef}>
        <View ref={headerRef} style={styles.upHeaderSection}>
          <Head open={!isCollapsed} />
        </View>
        <View style={{height: indent}}>
          <View ref={subheaderRef}>
            <SubHead />
          </View>
        </View>
      </View>
      <ScrollView
        style={{
          height: deviseHeight - headerHeight - indent - topIndent,
          marginTop: indent + headerHeight,
        }}
        onScroll={onScroll}
        scrollEventThrottle={16}
        ref={scrollWrapper}
      >
        {children}
      </ScrollView>
    </View>
  )
}

export default ScrollWithHeader

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  header: {
    position: 'absolute',
    width: '100%',
  },
  upHeaderSection: {
    paddingBottom: 18,
  },
  footerSection: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
})

interface IScrollWithHeader {
  topIndent: number
}
