import {Button, Icon, useStyleSheet} from '@ui-kitten/components'
import React, {FC} from 'react'

import {ImageProps} from 'react-native'
import {StyleService} from '@ui-kitten/components'

const CloseButton: FC<ICloseButton> = ({onPress}) => {
  const styles = useStyleSheet(themedStyles)

  const CloseIcon = (props: Partial<ImageProps> | undefined) => (
    <Icon
      fill="#333333"
      style={[props?.style, {width: 24, height: 24}]}
      animation="shake"
      name="close-outline"
    />
  )

  return (
    <Button
      appearance="outline"
      style={styles.button}
      onPress={onPress}
      accessoryRight={CloseIcon}
    />
  )
}

export default CloseButton

const themedStyles = StyleService.create({
  button: {
    zIndex: 10,
    position: 'absolute',
    backgroundColor: '#FAFBFB',
    top: 24,
    right: 24,
    marginTop: -10,
    width: 10,
    height: 10,
    borderRadius: 40,
    borderColor: '#FAFBFB',
  },
})

interface ICloseButton {
  onPress: () => void
}
