import {Button, Icon, useStyleSheet} from '@ui-kitten/components'
import React, {FC} from 'react'

import {ImageProps, Pressable} from 'react-native'
import {StyleService} from '@ui-kitten/components'

const QuestButton: FC<IQuestButton> = ({onPress}) => {
  const styles = useStyleSheet(themedStyles)

  const CustomIcon = (props: Partial<ImageProps> | undefined) => (
    <Icon
      fill="#333333"
      style={[props?.style, {width: 16, height: 16}]}
      animation="shake"
      name="question-mark-circle-outline"
    />
  )

  return (
    <Pressable style={styles.button} onPress={onPress}>
      <CustomIcon />
    </Pressable>
  )
}

const themedStyles = StyleService.create({
  button: {
    marginHorizontal: 5,
    paddingVertical: 0,
    zIndex: 10,
    backgroundColor: '#FAFBFB',
    width: 16,
    height: 16,
    borderColor: '#FAFBFB',
  },
})

export default QuestButton

interface IQuestButton {
  onPress: () => void
}
