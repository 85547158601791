import {Image, View} from 'react-native'
import React, {FC} from 'react'
import Head from './Head'
import OrderButtons from './OrderButtons'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const OrderDoneScreen: FC = () => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.wrapper}>
      <Head />
      <View style={styles.content}>
        <View style={styles.iconWrapper}>
          <Image
            style={styles.icon}
            source={require('../../assets/images/orderDone.jpg')}
          />
        </View>
        <OrderButtons />
      </View>
    </View>
  )
}

export default OrderDoneScreen

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'backgroundPrimary',
    paddingTop: 50,
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    flex: 1,
    backgroundColor: 'background',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    marginTop: 40,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: '85%',
    aspectRatio: 1,
  },
})
