import React from 'react'
import {View, Text, Image} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import Button from '../../ui/primitive/components/Button'
import Link from '../../ui/primitive/components/Link'

const WelcomeModal = ({openScanner}) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Scan to order</Text>
      <Text style={styles.subtitle}>
        Please scan the QR-Code in front of you to see the menu
      </Text>
      <View style={styles.iconContainer}>
        <Image
          style={styles.icon}
          source={require('../../assets/images/mobileQR.png')}
        />
      </View>
      <Button style={styles.button} onPress={openScanner} type={'blue'}>
        <Image
          style={styles.scannerIcon}
          source={require('../../assets/images/scannerIcon.png')}
        />
        <Text style={styles.buttonText}>Scan QR-Code</Text>
      </Button>
      <View style={styles.linkContainer}>
        <Link style={styles.link} onPress={() => {}}>
          <Text style={styles.linkText}>Learn more About Fonsy</Text>
        </Link>
      </View>
    </View>
  )
}

export default WelcomeModal

const themedStyles = StyleService.create({
  container: {
    padding: 24,
    width: 295,
    height: 397,
    backgroundColor: 'backgroundPrimary',
    borderRadius: 8,
    marginBottom: 20,
  },
  title: {
    fontSize: 28,
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'inter-regular',
    marginBottom: 12,
    fontStyle: 'italic',
  },
  iconContainer: {
    alignItems: 'center',
    marginBottom: 40,
  },
  icon: {
    width: 83,
    height: 100,
  },
  scannerIcon: {
    width: 16,
    height: 16,
  },
  button: {
    borderRadius: 28,
    marginBottom: 18,
  },
  buttonText: {
    fontSize: 15,
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    fontStyle: 'italic',
    marginLeft: 8,
    color: 'secondaryText',
    letterSpacing: 2,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  linkText: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'inter-regular',
    fontStyle: 'italic',
    letterSpacing: 2,
  },
  linkContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
