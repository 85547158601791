import {useNavigation} from '@react-navigation/core'
import useTransitionOrderToState from './useTransitionOrderToState'
import useActiveCustomer from './useActiveCustomer'
import {GET_ACTIVE_ORDER, useActiveOrder} from './useOrder'
import useGateway from './useGateway'
import {
  CurrencyCode,
  CustomPaymentMethodType,
  PaymentProviders,
} from '../types/global-types'
import useSetOrderCustomFields from './useSetOrderCustomFields'

const useOrderNow = () => {
  const navigation = useNavigation()
  const {data: customer} = useActiveCustomer()
  const [transitionOrderToState] = useTransitionOrderToState()
  const [createGateway] = useGateway()
  const {totalWithTax: price} = useActiveOrder()
  const [setOrderCustomFields] = useSetOrderCustomFields()

  return async (): Promise<void> => {
    try {
      if (!customer) {
        // now this is always, need add login mutation and login page
        navigation.navigate('PayNow')
        return console.error('no active customer')
      }

      const {data: transitionOrderToStateData} = await transitionOrderToState({
        variables: {
          OrderStates: 'ArrangingPayment',
        },
        refetchQueries: [GET_ACTIVE_ORDER],
      })
      // you need to add user data or add a product to the order
      if (transitionOrderToStateData?.transitionOrderToState) {
        const res = transitionOrderToStateData.transitionOrderToState
        if (res.__typename === 'OrderStateTransitionError') {
          throw new Error(res.message)
        }
      }

      // Data for payment
      const {data: createGatewayData} = await createGateway({
        variables: {
          amount: price,
          paymentMethod: CustomPaymentMethodType.PAYREXX,
          currency: CurrencyCode.CHF,
          pm: PaymentProviders.TWINT,
        },
      })

      // unknown error
      if (!createGatewayData?.createGateway) {
        throw new Error()
      }

      const data = createGatewayData.createGateway
      if (data.__typename === 'CreateGatewayError') {
        throw new Error(data.message)
      }

      if (!data.link) {
        throw new Error('Failed to create order, please try again later')
      }

      /* Save data about gateway */
      await setOrderCustomFields({
        variables: {
          customFields: {
            orderPaymentGateway: JSON.stringify(data),
            tipp: 0,
          },
        },
      })

      window.open(data.link, '_self')
    } catch (e: Error) {
      console.error(e)
      navigation.navigate('Error', {
        message: e.message,
      })
    }
  }
}

export default useOrderNow
