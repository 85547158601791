import React, {FC} from 'react'
import {Card, Modal, StyleService, useStyleSheet} from '@ui-kitten/components'
import {Modal as MobModal} from 'react-native'
import {detectDevice, Devices} from '../../../utils/detectDevice'
import CloseButton from '../../../ui/patterns/components/CloseButton'
import View from '../../../ui/primitive/components/View'

const AuthModal: FC<IAuthModal> = React.memo(
  ({isOpen, closeHandler, children, closable = true}) => {
    const styles = useStyleSheet(themedStyles)
    const isPC = [Devices.PC_WEB, Devices.MACOS].includes(detectDevice())

    if (!isPC && !isOpen) return null

    return (
      <>
        {isPC ? (
          <Modal
            visible={isOpen}
            backdropStyle={styles.backdrop}
            onBackdropPress={closeHandler}
          >
            <Card style={styles.card}>{children}</Card>
          </Modal>
        ) : (
          <MobModal
            animationType="slide"
            hardwareAccelerated
            onRequestClose={closeHandler}
            style={styles.backdrop}
          >
            <View style={styles.formContainer}>
              {closable && <CloseButton onPress={closeHandler} />}
              {/*<Card style={[styles.card]}>*/}
              {children}
            </View>
          </MobModal>
        )}
      </>
    )
  },
)

export default AuthModal

const themedStyles = StyleService.create({
  card: {},
  mobileCard: {
    marginTop: 80,
  },
  backdrop: {
    backgroundColor: 'backdropBackground',
  },
  formContainer: {
    flexGrow: 1,
    backgroundColor: 'backgroundPrimary',
    borderRadius: 0,
    backgroundColor: 'backgroundPrimary',
    border: 'none',
    flexGrow: 1,
  },
})

interface IAuthModal {
  isOpen: boolean
  closeHandler: () => void
  closable?: boolean
}
