import {ApolloQueryResult, gql, useQuery} from '@apollo/client'
import {useMemo} from 'react'
import {
  getActiveOrder,
  getActiveOrder_activeCustomer,
  getActiveOrder_activeOrder_customFields,
} from './types/getActiveOrder'
import {CUSTOMER_FRAGMENT} from './useActiveCustomer'

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    createdAt
    state
    totalQuantity
    totalWithTax
    lines {
      id
      quantity
      linePriceWithTax
      productVariant {
        id
        productId
        name
      }
    }
    customFields {
      orderPaymentGateway
      tableId
      customerName
    }
  }
`

export const ORDER_STATE_TRANSITION_ERROR_FRAGMENT = gql`
  fragment OrderStateTransitionError on OrderStateTransitionError {
    errorCode
    message
    transitionError
    fromState
    toState
  }
`

export const GET_ACTIVE_ORDER = gql`
  query getActiveOrder {
    activeOrder {
      ...OrderFragment
    }
    activeCustomer {
      ...ActiveCustomer
    }
  }
  ${ORDER_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
`

// FIXME: this hook loads and maps too much data and is used in too many places
// FIXME: it also does not memoize its data and returns a new object every time
// both problems lead to performance problems
export const useActiveOrder = (): IUseActiveOrder => {
  const {data, loading, refetch} = useQuery<getActiveOrder>(GET_ACTIVE_ORDER)

  const activeOrderProducts: IData = useMemo(() => {
    return Object.fromEntries(
      data?.activeOrder?.lines.map((item) => [
        item.productVariant.id,
        {
          orderLineId: item.id,
          linePriceWithTax: item.linePriceWithTax,
          name: item.productVariant.name,
          productId: item.productVariant.productId,
          productVariantId: item.productVariant.id,
          quantity: item.quantity,
        },
      ]) ?? [],
    )
  }, [data?.activeOrder?.lines])

  return {
    data: activeOrderProducts,
    id: data?.activeOrder?.id,
    productCount: data?.activeOrder?.totalQuantity || 0,
    totalWithTax: data?.activeOrder?.totalWithTax || 0,
    state: data?.activeOrder?.state || 'AddingItems',
    customer: data?.activeCustomer,
    loading,
    refetch,
    customFields: data?.activeOrder?.customFields,
  }
}

export interface IUseActiveOrder {
  data: IData
  id: string

  productCount: number
  totalWithTax: number
  state: string
  customer: undefined | null | getActiveOrder_activeCustomer

  loading: boolean
  refetch: () => Promise<ApolloQueryResult<getActiveOrder>>
  customFields: getActiveOrder_activeOrder_customFields | null | undefined
}

export interface IOrderDataItem {
  orderLineId: string
  linePriceWithTax: number
  productId: string
  productVariantId: string
  name: string
  quantity: number
}

export interface IData {
  [key: number]: IOrderDataItem
}
