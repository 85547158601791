import React, {useCallback, useState} from 'react'
import {View, Text, Image} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import Link from '../../ui/primitive/components/Link'
import {QrReader} from 'react-qr-reader'
import {urlParse} from '../../utils/subdomainHelpers'

const Scanner = ({closeScanner}) => {
  const styles = useStyleSheet(themedStyles)
  const [error, setError] = useState(false)

  const readQRHandler = useCallback(
    async (data) => {
      try {
        const parsedUrl = urlParse(data)
        if (parsedUrl && parsedUrl.tableId && parsedUrl.channel) {
          const newUrl = new URL(window.location.href)
          newUrl.pathname = 'collections/'
          newUrl.searchParams.set('tableId', parsedUrl.tableId)
          newUrl.searchParams.set('channel', parsedUrl.channel)
          window.location.href = newUrl.pathname + newUrl.search
        } else {
          throw 'QR code does not contain required data '
        }
      } catch (e) {
        console.error(e)
        setError(true)
      }
    },
    [setError],
  )

  return (
    <View style={styles.container}>
      <View style={styles.scannerContainer}>
        <QrReader
          constraints={{facingMode: 'environment', height: 200, width: 200}}
          onResult={(result, error) => {
            if (!!result) {
              readQRHandler(result.getText())
            }
            if (!!error) {
              console.info(error)
            }
          }}
        />
      </View>
      <View style={styles.errorContainer}>
        {error && <Text style={styles.errorText}>restaurant not found</Text>}
      </View>
      <Link onPress={closeScanner} style={styles.link}>
        <Text style={styles.linkText}>Back</Text>
      </Link>
    </View>
  )
}

export default Scanner

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
  },
  scannerContainer: {
    width: 295,
    borderRadius: 8,
    justifyContent: 'center',
  },
  button: {
    marginTop: 36,
    marginBottom: 28,
    borderRadius: 28,
    width: '100%',
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  buttonText: {
    fontSize: 15,
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    marginLeft: 8,
    color: 'secondaryText',
    letterSpacing: 2,
  },
  link: {
    marginTop: 28,
    marginBottom: 20,
  },
  linkText: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontFamily: 'inter-regular',
    letterSpacing: 2,
  },
  errorContainer: {
    marginTop: 5,
    width: '100%',
    height: 16,
    alignItems: 'center',
  },
  errorText: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontFamily: 'inter-regular',
    letterSpacing: 2,
    color: 'secondaryText',
  },
})
