import React, {FC} from 'react'
import CollectionsScreen from '../features/collections/CollectionsScreen'
import LinkingConfiguration from './LinkingConfiguration'
import {NavigationContainer} from '@react-navigation/native'
import NotFoundScreen from '../features/notFound/NotFoundScreen'
import OrderDoneScreen from '../features/orderDone/OrderDoneScreen'
import OrderOverviewScreen from '../features/orderOverview/OrderOverviewScreen'
import {RootStackParamList} from '../types'
import PayNowScreen from '../features/payNow/PayNowScreen'
import SuccessPaymentScreen from '../features/successPayment/SuccessPaymentScreen'
import HangOnScreen from '../features/hangOn/HangOnScreen'
import BarServiceScreen from '../features/barService/BarServiceScreen'
import BreakScreen from '../features/break/BreakScreen'
import ErrorScreen from '../features/error/ErrorScreen'
import useTransitionActiveOrderStateToAddingItems from '../hooks/useTransitionActiveOrderStateToAddingItems'
import QueryString from 'qs'
import useInitialNavigation from '../hooks/useInitialNavigation'
import TableScreen from '../features/tableService/TableScreen'

import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack'
import SplashScreen from '../features/splashScreen/SplashScreen'
import PaymentErrorScreen from '../features/paymentError/PaymentErrorScreen'
import ScannerPage from '../features/scannerPage'

const Navigation: FC<IProps> = ({params}) => {
  useTransitionActiveOrderStateToAddingItems()

  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator params={params} />
    </NavigationContainer>
  )
}

export default Navigation

interface IProps {
  params: QueryString.ParsedQs | undefined
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createStackNavigator<RootStackParamList>()

const RootNavigator: FC<IProps> = ({params}) => {
  useInitialNavigation()

  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forFadeFromBottomAndroid,
  }

  return (
    <Stack.Navigator initialRouteName="Scanner" detachInactiveScreens={false}>
      <Stack.Screen
        name="Scanner"
        component={ScannerPage}
        options={options}
      />
      <Stack.Screen
        name="Collections"
        component={CollectionsScreen}
        options={options}
        initialParams={params && {tableId: params.tableId as string, channel: params.channel as string}}
      />
      <Stack.Screen
        name="OrderOverview"
        component={OrderOverviewScreen}
        options={options}
      />
      <Stack.Screen
        name="OrderDone"
        component={OrderDoneScreen}
        options={options}
      />
      <Stack.Screen name="PayNow" component={PayNowScreen} options={options} />
      <Stack.Screen
        name="SuccessPayment"
        component={SuccessPaymentScreen}
        options={options}
      />
      <Stack.Screen name="HangOn" component={HangOnScreen} options={options} />
      <Stack.Screen
        name="BarService"
        component={BarServiceScreen}
        options={options}
      />
      <Stack.Screen name="Break" component={BreakScreen} options={options} />
      <Stack.Screen name="Error" component={ErrorScreen} options={options} />
      <Stack.Screen
        name="PaymentError"
        component={PaymentErrorScreen}
        options={options}
      />
      <Stack.Screen
        name="TableService"
        component={TableScreen}
        options={options}
      />
      <Stack.Screen
        name="SplashScreen"
        component={SplashScreen}
        options={options}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={options}
      />
    </Stack.Navigator>
  )
}
