import {Button} from '@ui-kitten/components'
import React, {FC, memo} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

import Colors from '../../../constants/Colors'

import {useAddItemToOrder} from '../../../hooks/useAddItemToOrder'
import MyIcon from '../../primitive/components/MyIcon'

const AddButton: FC<IAddButton> = memo(
  ({productId, disabled = false, fill}) => {
    const addItemToOrder = useAddItemToOrder()
    const styles = useStyleSheet(themedStyles)

    const PlusIcon = () => (
      <MyIcon
        fill={fill || '#0500FF'}
        style={styles.icon}
        name="plus-circle-outline"
      />
    )

    return (
      <Button
        appearance="ghost"
        style={[
          styles.button,
          disabled ? {backgroundColor: Colors.light.backgroundSecondary} : {},
        ]}
        disabled={disabled}
        onPress={() => !disabled && addItemToOrder(productId, 1)}
        accessoryRight={PlusIcon}
      />
    )
  },
)

export default AddButton

const themedStyles = StyleService.create({
  button: {
    width: 24,
    height: 24,
  },
  icon: {
    width: 24,
    height: 24,
  },
})

interface IAddButton {
  productId: string
  fill?: string
  disabled?: boolean
}
