import View from '../../ui/primitive/components/View'
import {StyleService, Text, useStyleSheet} from '@ui-kitten/components'
import priceTransform from '../../utils/priceTransform'
import MinusButton from '../../ui/patterns/components/MinusButton'
import AddButton from '../../ui/patterns/components/AddButton'
import React, {FC} from 'react'

const ReviewProduct: FC<IReviewProduct> = React.memo(({id, value}) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.productItem}>
      <View style={styles.productContentContainer}>
        <View style={styles.counter}>
          <Text style={styles.counterNumber}>{value.quantity}</Text>
        </View>
        <View style={styles.productDescription}>
          <Text
            numberOfLines={2}
            ellipsizeMode={'head'}
            style={styles.productName}>
            {value.name}
          </Text>
          <Text style={styles.productPrice}>
            CHF {priceTransform(value.linePriceWithTax)}
          </Text>
        </View>
      </View>
      <View style={styles.addingButtonContainer}>
        <MinusButton
          orderLineId={value.orderLineId}
          disabled={false}
          quantity={value.quantity}
        />
        <View style={styles.separator} />
        <AddButton productId={id} />
      </View>
    </View>
  )
})

export default ReviewProduct

const themedStyles = StyleService.create({
  productItem: {
    minWidth: 312,
    width: '100%',
    height: 64,
    position: 'relative',
    backgroundColor: '#F9F9F9',
    borderRadius: 4,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marinRight: 5,
  },
  counter: {
    width: 48,
    height: 64,
    backgroundColor: '#333333',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counterNumber: {
    color: '#FFFFFF',
    fontSize: 18,
  },
  productName: {
    color: '#333333',
    fontFamily: 'inter-bold',
    fontSize: 14,
    maxWidth: 150,
  },
  productPrice: {
    color: '#333333',
    fontFamily: 'inter-bold',
    fontSize: 14,
  },
  productDescription: {
    marginLeft: 12,
  },
  productContentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addingButtonContainer: {
    flexDirection: 'row',
    marginLeft: 'auto',
    position: 'relative',
    width: 100,
    justifyContent: 'space-between',
  },
  separator: {
    height: 57,
    width: 1,
    backgroundColor: '#C4C4C4',
    position: 'absolute',
    left: '50%',
    top: -5,
  },
})

export interface ReviewProductValue {
  quantity: number
  name: string
  linePriceWithTax: number
  orderLineId: string
}
interface IReviewProduct {
  id: string
  value: ReviewProductValue
}
