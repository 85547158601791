import React from 'react'
import {ScrollView, StyleSheet, View} from 'react-native'
import Colors from '../../constants/Colors'
import {useCollections} from '../../hooks/useCollections'
import CollectionItem from './CollectionItem'

const Collections = React.memo(() => {
  const {data} = useCollections()

  return (
    <ScrollView style={styles.wrapper}>
      {data?.collections.items
        .filter((item) => item.parent?.name === '__root_collection__')
        .map((rootCollection) => {
          const collections = data.collections.items.filter((collection) =>
            rootCollection.children?.find((i) => i.id === collection.id),
          )
          return (
            <View key={rootCollection.id} style={styles.container}>
              {collections.map((collection) => (
                <View key={collection.id}>
                  <View style={styles.menu}>
                    <CollectionItem
                      key={collection.id}
                      collectionId={collection.id}
                      name={collection.name}
                    />
                  </View>
                  <View />
                </View>
              ))}
            </View>
          )
        })}
    </ScrollView>
  )
})

export default Collections

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Colors.light.backgroundPrimary,
    height: '100%',
    paddingBottom: 150,
  },
  container: {
    backgroundColor: '#ffffff',
  },
  productList: {
    backgroundColor: Colors.light.lightGrey,
  },
  menu: {
    backgroundColor: Colors.light.backgroundSecondary,
    elevation: 9,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: 'bold',
    paddingTop: 32,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 25,
    color: 'primaryText',
  },
})
