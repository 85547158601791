import {useNavigation} from '@react-navigation/core'
import useSetOrderCustomFields from './useSetOrderCustomFields'
import {GET_ACTIVE_ORDER, useActiveOrder} from './useOrder'
import {useSetActiveOrder} from './useSetActiveOrder'
import {getTableId} from '../utils/getTable'
import {PAY_LATER_ORDERS} from './usePayLaterOrders'

const useSetPayLaterStatus = () => {
  const navigation = useNavigation()
  const [setOrderCustomFields] = useSetOrderCustomFields()
  const {id} = useActiveOrder()
  const [setInactiveOrder] = useSetActiveOrder()

  const handler = async (fields: Fields) => {
    try {
      const tableId = await getTableId()
      await setOrderCustomFields({
        variables: {
          customFields: {
            tableId: tableId as string,
            customerName: fields.customerName,
            createTime: new Date().getTime().toString() as string,
          },
        },
      })

      const resp = await setInactiveOrder({
        variables: {
          id,
          state: 'PayLater',
          active: false,
        },
        refetchQueries: [GET_ACTIVE_ORDER, PAY_LATER_ORDERS],
      })

      return resp
    } catch (e: Error) {
      navigation.navigate('Error', {
        message: e.message,
      })
    }
  }

  return [handler] as const
}

interface Fields {
  customerName: string
}

export default useSetPayLaterStatus
