import React, { FC } from 'react'
import { Pressable, StyleSheet, Text, ViewStyle } from 'react-native'

const Link: FC<ILink> = React.memo(({ children, style, onPress }) => {
  return (
    <Pressable onPress={onPress}>
      <Text style={[styles.linkText, style]}>{children}</Text>
    </Pressable>
  )
})

export default Link

const styles = StyleSheet.create({
  linkText: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
})

interface ILink {
  style?: ViewStyle | ViewStyle[]
  onPress: () => void
}
