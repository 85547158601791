import {Icon, StyleService, Text, useStyleSheet} from '@ui-kitten/components'
import View from '../../ui/primitive/components/View'
import React, {FC, useEffect, useState} from 'react'
import CircleButton from '../../ui/primitive/components/CircleButton'
import {getTableId} from '../../utils/getTable'
import {Dimensions, Modal, Pressable} from 'react-native'
import {useNavigation} from '@react-navigation/core'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'
import useAddCallWaiterAction from '../../hooks/useAddCallWaiterAction'
import PayCashModal from '../payNow/PayCashModal'

const windowHeight = Dimensions.get('window').height

const SideMenu: FC<ISideMenu> = ({onClose, modalVisible}) => {
  const styles = useStyleSheet(themedStyles)
  const [tableId, settableId] = useState('0')
  const navigation = useNavigation()
  const [payButtonVisible, setPayButtonVisible] = useState(false)
  const orders = usePayLaterOrders()
  const [callWaiter] = useAddCallWaiterAction()
  const [waiterNotifyVisible, setWaiterVisible] = useState(false)

  useEffect(() => {
    getTableId().then((id) => {
      settableId(id)
    })
  }, [])

  useEffect(() => {
    if (orders?.payLaterOrders.length) {
      setPayButtonVisible(true)
    }
  }, [orders])

  const goToMenu = () => {
    onClose()
    navigation.navigate('Collections')
  }

  const goToPay = () => {
    onClose()
    navigation.navigate('PayNow')
  }

  const callWaiterAction = async () => {
    await callWaiter()
    setWaiterVisible(true)
  }

  const notifyHandler = () => {
    setWaiterVisible(false)
    onClose()
  }

  return (
    <Modal animationType="slide" visible={modalVisible}>
      <PayCashModal
        visible={waiterNotifyVisible}
        handler={notifyHandler}
      ></PayCashModal>
      <View style={[styles.wrapper, {height: windowHeight}]}>
        <View style={styles.closeCircle}></View>
        <CircleButton
          style={styles.closeButton}
          iconElement={
            <Icon style={styles.icon} name="close-outline" fill="#000000" />
          }
          onClick={onClose}
        />
        <View style={styles.menuContainer}>
          <View style={styles.menuHeader}>
            <Text style={[styles.menuTitle, styles.text]}>
              Table NO. {tableId}
            </Text>
            <Text style={[styles.menuSubtitle, styles.text]}>
              View our menu online and order directly. Save time by paying by
              creditcard.
            </Text>
          </View>
          <View style={styles.menu}>
            <Pressable onPress={goToMenu}>
              <Text style={[styles.menuItem, styles.text]}>Menu</Text>
            </Pressable>
            {payButtonVisible && (
              <Pressable onPress={goToPay}>
                <Text style={[styles.menuItem, styles.text]}>Open Bills</Text>
              </Pressable>
            )}
            <Pressable onPress={callWaiterAction}>
              <Text style={[styles.menuItem, styles.text]}>Call Waiter</Text>
            </Pressable>
            <Text style={[styles.menuItem, styles.text]}>How it works?</Text>
          </View>
        </View>
        <View style={styles.referenceCircle}></View>
        <CircleButton
          style={styles.referenceButton}
          iconElement={
            <Icon style={styles.icon} name="bulb-outline" fill="#000000" />
          }
          onClick={() => {}}
        />
      </View>
    </Modal>
  )
}

interface ISideMenu {
  onClose: () => void
  modalVisible: boolean
}

const themedStyles = StyleService.create({
  wrapper: {
    overflow: 'hidden',
    backgroundColor: 'sideMenuBackground',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 8,
  },
  closeCircle: {
    position: 'absolute',
    width: 538,
    height: 538,
    top: -162,
    right: -308,
    zIndex: 6,
    backgroundColor: 'sideMenuCircle',
    borderRadius: 500,
  },
  closeButton: {
    top: 30,
    right: 24,
    position: 'absolute',
  },
  menuContainer: {
    paddingHorizontal: 24,
    zIndex: 7,
  },
  menuHeader: {
    marginBottom: 90,
  },
  menuTitle: {
    fontSize: 36,
    marginBottom: 12,
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
  },
  menuSubtitle: {
    fontSize: 16,
    fontFamily: 'inter-medium',
  },
  menu: {},
  menuItem: {
    fontSize: 21,
    marginVertical: 12,
    fontFamily: 'inter-bold',
  },
  referenceCircle: {
    width: 240,
    height: 240,
    position: 'absolute',
    backgroundColor: 'sideMenuCircle',
    zIndex: 6,
    bottom: -100,
    right: -100,
    borderRadius: 200,
  },
  referenceButton: {
    bottom: 56,
    right: 24,
    position: 'absolute',
  },
  text: {
    color: 'secondaryText',
  },
  icon: {
    width: 24,
    height: 24,
  },
})

export default SideMenu
