import {Dimensions, Pressable, Text, View} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import React from 'react'
import Button from '../../ui/primitive/components/Button'
import {Icon} from 'react-native-eva-icons'
import {useNavigation} from '@react-navigation/native'
import PaymentErrorSvg from './PaymentErrorSvg'

const windowHeight = Dimensions.get('window').height

const PaymentErrorScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  return (
    <View style={[styles.wrapper, {height: windowHeight}]}>
      <View style={styles.backdrop} />
      <View style={styles.card}>
        <Text style={styles.header}>Payment Error</Text>
        <Text style={styles.subHeader}>
          Something went wrong, please try again!
        </Text>
        <PaymentErrorSvg style={styles.smallImage} />
        <Button
          type={'blue'}
          style={styles.button}
          onPress={() => {
            navigation.navigate('PayNow')
          }}
        >
          <Icon
            style={styles.icon}
            name={'credit-card-outline'}
            fill={'#FFFFFF'}
          />
          <Text style={styles.buttonText}>try pay again</Text>
        </Button>
        <Pressable
          onPress={() => {
            navigation.navigate('Collections')
          }}
        >
          <Text style={styles.backText}>Go Back menu</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default PaymentErrorScreen

const themedStyles = StyleService.create({
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  backdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: '#999999',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  card: {
    width: '80%',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 24,
    display: 'flex',
  },
  header: {
    color: '#333333',
    textTransform: 'uppercase',
    fontSize: 28,
    fontFamily: 'oswald-bold',
    marginBottom: 12,
  },
  subHeader: {
    color: '#333333',
    fontFamily: 'inter-regular',
    fontSize: 14,
    marginBottom: 32,
  },
  smallImage: {
    marginHorizontal: 'auto',
    marginBottom: 32,
  },
  button: {
    height: 48,
    width: 247,
    borderRadius: 28,
    marginBottom: 16,
  },
  icon: {
    width: 22,
    height: 17,
  },
  buttonText: {
    color: '#FFFFFF',
    fontFamily: 'inter-bold',
    fontSize: 12,
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
  backText: {
    color: '#333333',
    fontFamily: 'inter-regular',
    fontSize: 12,
    letterSpacing: 2,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    marginHorizontal: 'auto',
  },
})
