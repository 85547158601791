import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {Text, View} from 'react-native'
import Colors from '../../constants/Colors'
import {getTableId} from '../../utils/getTable'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const Head: FC<IHead> = ({open}) => {
  const styles = useStyleSheet(themedStyles)
  const [tableId, setTableId] = useState('')

  useEffect(() => {
    getTableId().then((id) => setTableId(id))
  }, [setTableId])

  return (
    <View style={styles.titleContainer}>
      <View style={styles.titleGreetings}>
        {tableId ? (
          <Text style={styles.tableTitle}>Table #{tableId}</Text>
        ) : (
          <Text style={styles.tableTitle}>Table</Text>
        )}
      </View>
    </View>
  )
}

export default Head

const themedStyles = StyleService.create({
  titleContainer: {
    paddingLeft: 24,
    paddingRight: 14,
  },
  titleGreetings: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: Colors.light.primary,
  },
  img: {
    width: 28,
    height: 28,
  },
  basket: {
    position: 'relative',
  },
  redDot: {
    position: 'absolute',
    width: 7,
    height: 7,
    borderRadius: 7,
    right: 0,
    top: 0,
    backgroundColor: '#fd065c',
  },
  tableTitle: {
    fontSize: 24,
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
  },
})

interface IHead {
  open: boolean
}
