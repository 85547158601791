export const intervalCounter = (
  from: number,
  to: number,
  callback: (counter: number) => void,
  onEnd: () => void,
) => {
  if (from === to) return
  const direction = from < to
  let counter = from
  const difference = Math.abs(to - from)
  const value = +(difference / 25).toFixed()
  const interval = setInterval(() => {
    const endHandler = () => {
      callback(to)
      clearInterval(interval)
      onEnd()
    }
    if (direction) {
      counter = counter + value
      if (counter >= to) {
        endHandler()
        return
      }
      callback(counter)
    } else {
      counter = counter - value
      if (counter <= to) {
        endHandler()
        return
      }
      callback(counter)
    }
  }, 1)
}
