import React, {FC} from 'react'
import {View} from 'react-native'
import TipButtonsList from './TipButtonsList'
import TipsLabel from './TipsLabel'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const Tips: FC = React.memo(() => {
  const styles = useStyleSheet(themedStyles)
  return (
    <View style={styles.tipContainer}>
      <TipsLabel />
      <TipButtonsList />
    </View>
  )
})

export default Tips

const themedStyles = StyleService.create({
  tipContainer: {
    marginTop: 27,
    paddingHorizontal: 22,
    marginBottom: 150,
  },
})
