import {Products, Products_products_items} from './types/Products'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'

export const PRODUCT_BASE_FRAGMENT = gql`
  fragment ProductBaseFragment on Product {
    id
    name
    description
  }
`
export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment ProductVariantFragment on ProductVariant {
    id
    name
    price
    priceWithTax
    stockLevel
  }
`

export const PRODUCTS = gql`
  query Products {
    products {
      items {
        ...ProductBaseFragment
        variants {
          ...ProductVariantFragment
        }
        collections {
          id
          name
        }
      }
    }
  }
  ${PRODUCT_VARIANT_FRAGMENT}
  ${PRODUCT_BASE_FRAGMENT}
`

export const useProducts = () => {
  const {data: {products: {items = []} = {}} = {}} =
    useQuery<Products>(PRODUCTS)
  return items
}

export const getProductsForCollection = (
  collectionId: string,
  products: Products_products_items[],
) => {
  return products.filter((product: Products_products_items) => {
    return !!product.collections.find(
      (collection) => collection.id === collectionId,
    )
  })
}
