import Button from '../../primitive/components/Button'
import MyIcon from '../../primitive/components/MyIcon'
import React from 'react'
import {StyleService, useStyleSheet, Text} from '@ui-kitten/components'
import {useWindowDimensions} from 'react-native'

const PaymentButton = ({disabled, text, onClick, visibleIcon = true}) => {
  const styles = useStyleSheet(themedStyles)
  const {width} = useWindowDimensions()

  const smallestDevice = width < 351

  return (
    <Button
      type={'blue'}
      style={[styles.button, smallestDevice && {width: '90%'}]}
      disabled={disabled}
      onPress={onClick}>
      {visibleIcon && (
        <MyIcon
          fill={disabled ? '#0500FF' : '#FFFFFF'}
          name={'credit-card-outline'}
        />
      )}

      <Text style={[styles.text, disabled && styles.disabledText]}>{text}</Text>
    </Button>
  )
}

export default PaymentButton

const themedStyles = StyleService.create({
  text: {
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    letterSpacing: 2,
    marginLeft: 7,
  },
  disabledText: {
    color: '#0500FF',
  },
  button: {
    borderWidth: 0,
    borderRadius: 50,
    height: 56,
    paddingHorizontal: 30,
    maxWidth: 337,
    width: '100%',
  },
})
