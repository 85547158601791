import AsyncStorage from '@react-native-async-storage/async-storage'
import {useSetPayment} from './useSetPayment'
import {useSetActiveOrder} from './useSetActiveOrder'

const useAfterSuccessPay = () => {
  const [setPayment] = useSetPayment()
  const [setInactiveOrder] = useSetActiveOrder()

  return async (): Promise<void> => {
    try {
      const metadata = await AsyncStorage.getItem('@payment_gateway')
      const keysString = await AsyncStorage.getItem('@payment_order_ids')
      const tip = await AsyncStorage.getItem('@payment_last_tip')
      const keys = keysString.split(',')

      console.log('metadata', metadata)
      console.log('keysString', keysString)
      console.log('keys', keys)
      console.log('tip', tip)

      // for (const key of keys) {
      //   try {
      await setPayment({
        variables: {
          orderIdsList: keys,
          method: 'payrexx',
          tip: +tip,
          metadata: metadata,
        },
      })
        // } catch (e) {
        //   console.log('PaymentError', e)
        // }
      // }


      // for (const plOrderId of keys) {
      //   await setInactiveOrder({
      //     variables: {
      //       id: plOrderId,
      //       state: 'ArrangingPayment',
      //       active: true,
      //     },
      //   })
      // }
    } catch (e) {
      console.log('error', e)
    }
  }
}

export default useAfterSuccessPay
