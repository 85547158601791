import {gql, useQuery} from '@apollo/client'

export const CHANNEL_FRAGMENT = gql`
  fragment ChannelFragment on Channel {
    code
    id
    currencyCode
    customFields {
      channelState
      address
      channelState
      colour
      logo
      phone
    }
  }
`

export const USE_ACTIVE_CHANNEL = gql`
  query ActiveChannel {
    activeChannel {
      ...ChannelFragment
    }
  }
  ${CHANNEL_FRAGMENT}
`

// No change enum, because copy enum save in other apps
export enum ChannelStates {
  TABLE_ORDERING = 'Table ordering',
  ORDER_AT_THE_BAR = 'Order at the bar',
  TABLE_SERVICE = 'Table service',
  WE_ARE_CLOSED = 'We are closed!',
}

export const useActiveChannel = () => {
  const data = useQuery(USE_ACTIVE_CHANNEL)

  return {...data, data: data.data?.activeChannel}
}
