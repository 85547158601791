import {gql, useMutation} from '@apollo/client'
import {GET_ACTIVE_ORDER} from './useOrder'
import {PAY_LATER_ORDERS} from './usePayLaterOrders'

export const SET_ORDER_STATE = gql`
  mutation SetActiveState($id: String!, $state: String!, $active: Boolean!) {
    setActiveState(id: $id, state: $state, active: $active) {
      ... on Order {
        id
        customFields {
          orderPaymentGateway
        }
      }
    }
  }
`

export const useSetActiveOrder = () => {
  return useMutation(SET_ORDER_STATE, {
    refetchQueries: [GET_ACTIVE_ORDER],
    awaitRefetchQueries: true,
  })
}
