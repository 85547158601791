import React from 'react'
import {Image, Text, View} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const CollectionsWaitProducts = ({name}) => {
  const styles = useStyleSheet(themedStyles)
  return (
    <View style={styles.container}>
      <Text style={[styles.text]}>{name}</Text>
      <Image
        style={styles.image}
        source={require('../../assets/icons/waitingIcon.png')}
      />
    </View>
  )
}

export default CollectionsWaitProducts

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    paddingLeft: 16,
    fontSize: 18,
    backgroundColor: '#F8F8F8',
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    width: 24,
    height: 24,
    marginRight: 20,
  },
  text: {
    fontSize: 18,
    color: 'primaryText',
    textTransform: 'uppercase',
    paddingLeft: 10,
    fontFamily: 'oswald-bold',
  },
})
