import { Button, Icon } from '@ui-kitten/components'
import React, { FC } from 'react'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

import { ImageProps } from 'react-native'

const MoreButton: FC<IMoreButton> = ({ onPress }) => {
  const styles = useStyleSheet(themedStyles)

  const MoreIcon = (props: Partial<ImageProps> | undefined) => (
    <Icon
      fill="#333333"
      style={[props?.style, { width: 24, height: 24 }]}
      animation="shake"
      name="corner-down-right-outline"
    />
  )

  return (
    <Button
      appearance="ghost"
      style={styles.button}
      onPress={onPress}
      accessoryRight={MoreIcon}
    />
  )
}

export default MoreButton

const themedStyles = StyleService.create({
  button: {
    marginTop: -10,
    width: 10,
    height: 10,
  },
})

interface IMoreButton {
  onPress: () => void
}
