import React, {FC, useEffect, useState} from 'react'
import {View, Text, ScrollView, Dimensions} from 'react-native'
import Buttons from './buttons'
import {Icon, StyleService, useStyleSheet} from '@ui-kitten/components'
import {getTableId} from '../../utils/getTable'
import OrdersList from './OrdersList'
import Tips from './Tips'
import SideMenu from '../SideMenu/SideMenu'
import CircleButton from '../../ui/primitive/components/CircleButton'

export const OrderContext = React.createContext<IOrderContext>({
  tips: 0,
  setTips: () => {},
  totalPrice: 0,
  setTotalPrice: () => {},
  error: '',
  setError: () => {},
  checkOrdersIds: {},
  setCheckOrdersIds: () => {},
  orderCustomerName: '',
  setOrderCustomerName: () => {},
})

const windowHeight = Dimensions.get('window').height

const OrderProvider: FC = ({children}) => {
  const [tips, setTips] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [error, setError] = useState('')
  const [checkOrdersIds, setIds] = useState({})
  const [orderCustomerName, setOrderCustomerName] = useState('')

  const setCheckOrdersIds = (status: boolean, id: string) => {
    const obj = Object.assign({}, checkOrdersIds)
    obj[id] = status
    setIds(obj)
  }

  return (
    <OrderContext.Provider
      value={{
        tips,
        totalPrice,
        setTotalPrice,
        setTips,
        setError,
        error,
        checkOrdersIds,
        setCheckOrdersIds,
        orderCustomerName,
        setOrderCustomerName,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

const Orders: FC = () => {
  const styles = useStyleSheet(themedStyles)
  const [tableId, setTableId] = useState('')
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  useEffect(() => {
    getTableId().then(setTableId)
  }, [])

  return (
    <OrderProvider>
      <SideMenu
        modalVisible={sideMenuOpen}
        onClose={() => {
          setSideMenuOpen(false)
        }}
      />
      <View style={[styles.wrapper, {height: windowHeight}]}>
        <ScrollView>
          <View style={styles.headerConatiner}>
            <Text style={styles.header}>Table No. {tableId ?? 1}</Text>
            <CircleButton
              onClick={() => {
                setSideMenuOpen(true)
              }}
              style={styles.menuButton}
              iconElement={
                <Icon style={styles.icon} name="menu-outline" fill="#000000" />
              }
            />
          </View>

          <Text style={styles.subHeader}>Ready to Pay</Text>
          <OrdersList />
          <Tips />
        </ScrollView>
        <Buttons />
      </View>
    </OrderProvider>
  )
}

export default Orders

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'background',
    position: 'relative',
    overflow: 'hidden',
  },
  header: {
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
    fontSize: 24,
  },
  subHeader: {
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
    marginLeft: 24,
    fontSize: 24,
  },
  headerConatiner: {
    marginLeft: 24,
    marginTop: 64,
    marginBottom: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuButton: {
    zIndex: 3,
    backgroundColor: 'lightLightGrey',
    border: 0,
    marginRight: 24,
  },
  icon: {
    width: 24,
    height: 24,
  },
})

interface IOrderContext {
  tips: number
  totalPrice: number
  setTips: (prev: number) => void
  setTotalPrice: (prev: number) => void
  setError: (prev: string) => void
  error: string
  checkOrdersIds: object
  setCheckOrdersIds: (status: boolean, id: string) => void
  orderCustomerName: string
  setOrderCustomerName: (name: string) => void
}
