import * as React from 'react'
import Svg, {Path} from 'react-native-svg'

const ErrorShield = (props) => (
  <Svg
    width={18}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.467.133a1.75 1.75 0 0 1 1.066 0l5.25 1.68A1.75 1.75 0 0 1 14 3.48V7c0 1.566-.32 3.182-1.303 4.682-.983 1.498-2.585 2.813-5.032 3.855a1.7 1.7 0 0 1-1.33 0c-2.447-1.042-4.049-2.357-5.032-3.855C.32 10.182 0 8.566 0 7V3.48a1.75 1.75 0 0 1 1.217-1.667l5.25-1.68Zm.61 1.429a.25.25 0 0 0-.153 0l-5.25 1.68a.25.25 0 0 0-.174.238V7c0 1.358.275 2.666 1.057 3.86.784 1.194 2.121 2.34 4.366 3.297a.2.2 0 0 0 .154 0c2.245-.956 3.582-2.104 4.366-3.298C12.225 9.666 12.5 8.36 12.5 7V3.48a.25.25 0 0 0-.174-.237l-5.25-1.68ZM8 10.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-.25-5.75a.75.75 0 1 0-1.5 0v3a.75.75 0 0 0 1.5 0v-3Z"
      fill="red"
    />
  </Svg>
)

export default ErrorShield
