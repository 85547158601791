import {useActiveOrder} from './useOrder'
import {useEffect, useState} from 'react'
import useTransitionOrderToState from './useTransitionOrderToState'

/* This call is needed to be able to further add products. */
const useTransitionActiveOrderStateToAddingItems = () => {
  const [firstRenderState, setFirstRenderState] = useState('')
  const {state} = useActiveOrder()
  const [transitionOrderToState] = useTransitionOrderToState()

  useEffect(() => {
    if (state) {
      setFirstRenderState(state)
    }
  }, [state, setFirstRenderState])

  useEffect(() => {
    async function load() {
      try {
        if (state === 'ArrangingPayment' && !firstRenderState) {
          const {data} = await transitionOrderToState({
            variables: {OrderStates: 'AddingItems'},
          })

          if (
            data?.transitionOrderToState?.__typename ===
            'OrderStateTransitionError'
          ) {
            console.error(data.transitionOrderToState.message)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    load()
  }, [firstRenderState, state, transitionOrderToState])
}

export default useTransitionActiveOrderStateToAddingItems
