import {gql, useQuery} from '@apollo/client'
import {GetActiveCustomer} from './types/GetActiveCustomer'

export const CUSTOMER_FRAGMENT = gql`
  fragment ActiveCustomer on Customer {
    id
    emailAddress
    firstName
    lastName
  }
`

export const GET_ACTIVE_CUSTOMER = gql`
  query GetActiveCustomer {
    activeCustomer {
      ...ActiveCustomer
    }
  }
  ${CUSTOMER_FRAGMENT}
`

const useActiveCustomer = () => {
  const {data} = useQuery<GetActiveCustomer>(GET_ACTIVE_CUSTOMER)
  return {data: data?.activeCustomer}
}

export default useActiveCustomer
