import React, {FC} from 'react'
import {ScrollView, Text, View} from 'react-native'
import AddButton from '../../ui/patterns/components/AddButton'
import MinusButton from '../../ui/patterns/components/MinusButton'
import Price from '../../ui/patterns/components/Price'
import {useActiveOrder} from '../../hooks/useOrder'
import Total from './cart/Total'
import MyOrders from './MyOrders'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'

const OrderOverview: FC = () => {
  const {data} = useActiveOrder()
  const orders = Object.keys(data)
  const payLaterOrders = usePayLaterOrders()
  const styles = useStyleSheet(themedStyles)

  return (
    <ScrollView>
      {!!orders.length && (
        <View style={styles.container}>
          <Text style={styles.title}>Order #1</Text>
          {orders.map((productId) => (
            <View key={productId}>
              <View style={styles.row}>
                <Text style={styles.quantity}>{data[productId].quantity}x</Text>
                <Text
                  style={styles.name}
                  numberOfLines={2}
                  ellipsizeMode={'head'}
                >
                  {data[productId].name}
                </Text>
                <Price
                  style={styles.price}
                  price={data[productId].linePriceWithTax}
                />
                <View style={styles.buttons}>
                  <MinusButton
                    orderLineId={data[productId].orderLineId}
                    quantity={data[productId].quantity}
                    fill="#000000"
                    disabled={false}
                  />

                  <AddButton productId={productId} fill="#000000" />
                </View>
              </View>
              <View style={styles.line} />
            </View>
          ))}
          <Total />
        </View>
      )}
      {!!payLaterOrders?.length && <MyOrders />}
    </ScrollView>
  )
}

export default OrderOverview

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'backgroundPrimary',
    padding: 21,
    paddingBottom: 50,
  },
  name: {
    maxWidth: 150,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 6,
    paddingTop: 14,
    paddingLeft: 6,
    alignItems: 'center',
  },
  quantity: {
    marginRight: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 29,
  },
  price: {
    marginLeft: 'auto',
    marginRight: 20,
    fontWeight: 'bold',
  },
  line: {
    width: '100%',
    height: 0.5,
    backgroundColor: 'greyLineDark',
  },
  buttons: {
    flexDirection: 'row',
  },
})
