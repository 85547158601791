import * as Font from 'expo-font'
import React, {useEffect, useState} from 'react'
import * as SplashScreen from 'expo-splash-screen'
import {FontAwesome} from '@expo/vector-icons'
import * as Linking from 'expo-linking'
import QueryString from 'qs'

export default function useCachedResources() {
  const [params, setParams] = useState<QueryString.ParsedQs | undefined>(
    undefined,
  )
  const [initialPage, setInitialPage] = useState(false)
  const [isLoadingComplete, setLoadingComplete] = React.useState(false)

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync()

        // Load fonts
        const [queryParams] = await Promise.all([
          Linking.parseInitialURLAsync(),
          Font.loadAsync({
            ...FontAwesome.font,
            'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
            'inter-regular': require('../assets/fonts/Inter-Regular.ttf'),
            'inter-medium': require('../assets/fonts/Inter-Medium.ttf'),
            'inter-bold': require('../assets/fonts/Inter-Bold.ttf'),
            'oswald-bold': require('../assets/fonts/Oswald-Bold.ttf'),
          }),
        ])
        if (queryParams.path === '' || queryParams.path === 'collections') {
          setInitialPage(true)
        }

        if (queryParams.queryParams) {
          setParams(queryParams.queryParams)
        }
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
        SplashScreen.hideAsync()
      }
    }

    loadResourcesAndDataAsync()
  }, [])

  return {isLoadingComplete, params, initialPage}
}
