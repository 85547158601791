import React, { FC } from 'react'
import { Image, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const SplashScreen: FC<ISplashScreen> = ({ url, showLogo, color }) => {
  const styles = useStyleSheet(themedStyles)
  if (!showLogo) return null

  return (
    <View style={[styles.container, { backgroundColor: color || 'white' }]}>
      <Image source={{ uri: url }} style={styles.image} />
    </View>
  )
}

export default SplashScreen

interface ISplashScreen {
  url: string
  showLogo: boolean
  color: string
}

const themedStyles = StyleService.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: '100%',
  },
  image: {
    width: '56%',
    aspectRatio: 1,
    resizeMode: 'cover',
  },
})
