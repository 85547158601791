import OrderOverview from '../OrderOverview'
import OrderOverviewButtonContainer from '../../../ui/layout/components/OrderOverviewButtonContainer'
import OrderButtons from '../OrderButtons'
import React, {FC} from 'react'

const Cart: FC = () => {
  return (
    <>
      <OrderOverview />
      <OrderOverviewButtonContainer>
        <OrderButtons />
      </OrderOverviewButtonContainer>
    </>
  )
}

export default Cart
