export enum ActiveTips {
  FIVE = 'five',
  FIFTEEN = 'fifteen',
  YOUR_TIP = 'yourTip',
}

export const tipsObjectsArray = [
  {
    label: '5%',
    percent: 0.05,
    id: ActiveTips.FIVE,
    style: {left: 53},
  },
  {
    label: '15%',
    percent: 0.15,
    id: ActiveTips.FIFTEEN,
    style: {left: '50%'},
  },
]

export const tipsHelpFunc = (
  array: typeof tipsObjectsArray,
  totalWithTax: number,
) => {
  return array.map(({percent, label, id, style}) => {
    const tip = tipCutter(totalWithTax * percent)
    return {value: +tip, label, id, style}
  })
}

export function tipCutter(inputTip: number) {
  let tip = inputTip.toFixed(0)
  const lastNumber = +tip.split('').pop()
  if (lastNumber < 3) tip = tip.slice(0, -1) + '0'
  if (lastNumber >= 3 && lastNumber < 8) tip = tip.slice(0, -1) + '5'
  if (lastNumber >= 8) {
    tip = tip.slice(0, -1) + '0'
    tip = (+tip + 10).toString()
  }
  return tip
}

export function tipTransform(tip: number) {
  return Number(tip / 100).toFixed(1)
}
