import { useNavigation } from '@react-navigation/core'
import OrderOverviewButtonContainer from './OrderOverviewButtonContainer'
import Button from '../../primitive/components/Button'
import { StyleSheet, Text } from 'react-native'
import React from 'react'
import Colors from '../../../constants/Colors'

const SeeOurMenuButton = () => {
  const navigation = useNavigation()

  return (
    <OrderOverviewButtonContainer>
      <Button
        type="secondary"
        onPress={() => navigation.navigate('Collections')}>
        <Text style={styles.buttonText}>See our Menu</Text>
      </Button>
    </OrderOverviewButtonContainer>
  )
}

export default SeeOurMenuButton

const styles = StyleSheet.create({
  buttonText: {
    color: Colors.light.secondaryText,
    fontWeight: '500',
    fontSize: 18,
  },
})
