import {StyleSheet, View} from 'react-native'
import Collections from './Collections'
import React, {FC, useEffect, useState} from 'react'
import OrderButton from './OrderButton'
import ScrollWithHeader from './ScrollWithHeader'
import {useActiveOrder} from '../../hooks/useOrder'
import SplashScreen from '../splashScreen/SplashScreen'
import ReviewOrder from '../reviewOrder/ReviewOrder'
import CircleButton from '../../ui/primitive/components/CircleButton'
import {Icon} from '@ui-kitten/components'
import SideMenu from '../SideMenu/SideMenu'
import {useNavigation} from '@react-navigation/core'
import {getTableId} from '../../utils/getTable'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'
import {ChannelStates, useActiveChannel} from '../../hooks/useActiveChannel'
import BreakScreen from '../break/BreakScreen'

const CollectionsScreen: FC = () => {
  const [splashScreenVisible, setSplashScreenVisible] = useState(true)
  const [orderRewieved, setOrderRewieved] = useState(false)
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [payButtonVisible, setPayButtonVisible] = useState(false)
  const navigation = useNavigation()
  const orders = usePayLaterOrders()
  const {data: channel} = useActiveChannel()
  const [closeSplashVisible, setCloseSplashVisible] = useState(false)

  useEffect(() => {
    setTableIdInStorage()
  }, [])

  useEffect(() => {
    setPayButtonVisible(!!orders?.payLaterOrders.length)
  }, [orders])

  useEffect(() => {
    if (
      channel &&
      channel.customFields.channelState === ChannelStates.WE_ARE_CLOSED
    ) {
      setCloseSplashVisible(true)
    }
  }, [channel])

  const setTableIdInStorage = async () => {
    const tableId = await getTableId()
    await AsyncStorage.setItem('@table_id', tableId)
  }

  const openRewiev = () => {
    setOrderRewieved(true)
  }

  const closeRewiev = () => {
    setOrderRewieved(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setSplashScreenVisible(false)
    }, 2000)
  }, [])

  return (
    <View style={styles.wrapper}>
      {splashScreenVisible && <SplashScreen />}
      {closeSplashVisible && <BreakScreen />}
      <ReviewOrder visible={orderRewieved} onClose={closeRewiev} />
      <SideMenu
        modalVisible={sideMenuOpen}
        onClose={() => {
          setSideMenuOpen(false)
        }}
      />
      <CircleButton
        onClick={() => {
          setSideMenuOpen(true)
        }}
        style={styles.menuButton}
        iconElement={
          <Icon style={styles.icon} name="menu-outline" fill="#000000" />
        }
      />
      {payButtonVisible && (
        <CircleButton
          onClick={() => {
            navigation.navigate('PayNow')
          }}
          style={styles.payButton}
          iconElement={
            <Icon
              style={styles.icon}
              name="credit-card-outline"
              fill="#ffffff"
            />
          }
        />
      )}
      <ScrollWithHeader topIndent={50}>
        <Collections />
      </ScrollWithHeader>
      <OrderButton onPress={openRewiev} />
    </View>
  )
}

export default CollectionsScreen

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: 'white',
    paddingTop: 50,
    height: '100%',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#EDEFF3',
  },
  icon: {
    width: 24,
    height: 24,
  },
  menuButton: {
    zIndex: 3,
    backgroundColor: 'lightLightGrey',
    border: 0,
    position: 'absolute',
    top: 10,
    right: 18,
  },
  payButton: {
    zIndex: 3,
    backgroundColor: '#0500FF',
    border: 0,
    position: 'absolute',
    top: 10,
    right: 58,
  },
})
