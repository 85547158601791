import {Button, Icon, StyleService, useStyleSheet} from '@ui-kitten/components'
import React, {FC} from 'react'
import {Pressable, ViewStyle} from 'react-native'

const CircleButton: FC<ICircleButton> = ({
  iconElement,
  onClick,
  style,
  hasBackground = true,
}) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <Pressable
      style={[styles.circleButton, hasBackground && styles.background, style]}
      onPress={onClick}>
      {iconElement}
    </Pressable>
  )
}

const themedStyles = StyleService.create({
  circleButton: {
    height: 40,
    width: 40,
    zIndex: 8,
    background: false,
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    borderRadius: 100,
    backgroundColor: 'white',
  },
})

interface ICircleButton {
  iconElement: React.ReactElement
  onClick: () => void
  style?: ViewStyle | ViewStyle[]
  hasBackground?: boolean
}

export default CircleButton
