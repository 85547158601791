import {gql, useQuery} from '@apollo/client'

const ANNOUNCEMENT_QUERY = gql`
  query getAnnouncementText {
    getAnnouncementText {
      text
    }
  }
`

export const useAnnouncementText = () => {
  const data = useQuery(ANNOUNCEMENT_QUERY)
  return {
    announcement: data.data?.getAnnouncementText.text,
    loading: data.loading,
  }
}
