import {useNavigation} from '@react-navigation/core'
import {useSetActiveOrder} from './useSetActiveOrder'
import {usePayLaterOrders} from './usePayLaterOrders'
import {getOrderIds} from '../utils/getOrderIds'
import {useContext} from 'react'
import {OrderContext} from '../features/payNow/Orders'

const useSetWaitWaiter = () => {
  const navigation = useNavigation()
  const {checkOrdersIds} = useContext(OrderContext)
  const orders = usePayLaterOrders()
  const [setInactiveOrder] = useSetActiveOrder()

  const handler = async () => {
    try {
      const keys = Object.keys(checkOrdersIds)
      let allOrdersIds = getOrderIds(orders.payLaterOrders)

      keys.map((elem) => {
        if (checkOrdersIds[elem]) {
          allOrdersIds = allOrdersIds.filter((item) => item !== elem)
        }
      })

      for (let ordId of allOrdersIds) {
        await setInactiveOrder({
          variables: {
            id: ordId,
            state: 'WaitingWaiters',
            active: true,
          },
        })
      }
    } catch (e: Error) {
      navigation.navigate('Error', {
        message: e.message,
      })
    }
  }

  return [handler] as const
}

export default useSetWaitWaiter
