import {gql, useMutation} from '@apollo/client'
import {
  TransitionOrderToState,
  TransitionOrderToStateVariables,
} from './types/TransitionOrderToState'
import {ORDER_STATE_TRANSITION_ERROR_FRAGMENT} from './useOrder'

export const TRANSITION_ORDER_TO_STATE = gql`
  mutation TransitionOrderToState($OrderStates: String!) {
    transitionOrderToState(state: $OrderStates) {
      ...OrderStateTransitionError
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }

  ${ORDER_STATE_TRANSITION_ERROR_FRAGMENT}
`

const useTransitionOrderToState = () => {
  return useMutation<TransitionOrderToState, TransitionOrderToStateVariables>(
    TRANSITION_ORDER_TO_STATE,
  )
}

export default useTransitionOrderToState
