import React, {FC, useEffect, useRef} from 'react'
import {StyleSheet, TextInput, View} from 'react-native'
import Colors from '../../../constants/Colors'
import {ITextInput} from './Input'

const NumericInput: FC<INumericInput> = ({
  style,
  styleWrapper,
  value,
  onChangeText,
  placeholder,
  onFocus,
  onBlur,
  onSubmitEditing,
}) => {
  const textInput = useRef(null)

  useEffect(() => {
    textInput.current.focus()
  }, [])

  return (
    <View style={[styles.wrapper, styleWrapper]}>
      <TextInput
        style={[styles.container, style]}
        onChangeText={onChangeText}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        keyboardType="number-pad"
        placeholderTextColor={Colors.light.placeholder}
        ref={textInput}
        onSubmitEditing={onSubmitEditing}
      />
    </View>
  )
}

export default NumericInput

const styles = StyleSheet.create({
  container: {
    maxWidth: 146,
    maxHeight: 100,
    width: '100%',
    textAlign: 'center',
    height: '100%',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    flexDirection: 'row-reverse',
    marginRight: 0,
  },
})

export interface INumericInput extends ITextInput {
  onFocus?: () => void
  onBlur?: () => void
  onSubmitEditing?: () => void
}
