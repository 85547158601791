import {gql, useMutation} from '@apollo/client'
import {CreateGateway, CreateGatewayVariables} from './types/CreateGateway'

export const CREATE_GATEWAY = gql`
  mutation CreateGateway(
    $amount: Int!
    $paymentMethod: CustomPaymentMethodType!
    $currency: CurrencyCode!
    $pm: PaymentProviders!
    $idsList: [String]!
  ) {
    createGateway(
      amount: $amount
      currency: $currency
      paymentMethod: $paymentMethod
      pm: $pm
      idsList: $idsList
    ) {
      ... on CreateGatewayResult {
        amount
        createdAt
        currency
        hash
        id
        link
        preAuthorization
        referenceId
        requestId
        sku
        status
        vatRate
      }
      ... on CreateGatewayError {
        errorCode
        message
      }
    }
  }
`

const useGateway = () => {
  return useMutation(CREATE_GATEWAY)
}

export default useGateway
