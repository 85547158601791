import {GetCollections, GetCollectionsVariables} from './types/GetCollections'

import {CollectionListOptions} from '../types/global-types'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'

export const COLLECTIONS = gql`
  query GetCollections($options: CollectionListOptions) {
    collections(options: $options) {
      items {
        id
        name
        slug
        parent {
          id
          name
        }
        children {
          id
          name
        }
        featuredAsset {
          id
          preview
        }
      }
    }
  }
`

export const useCollections = (options?: CollectionListOptions) =>
  useQuery<GetCollections, GetCollectionsVariables>(COLLECTIONS, {
    variables: {
      options,
    },
  })
