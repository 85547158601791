import { Modal, Pressable, ScrollView, View } from 'react-native';
import {StyleService, useStyleSheet, Text}    from '@ui-kitten/components'
import MyIcon from '../../ui/primitive/components/MyIcon'
import Button from '../../ui/primitive/components/Button'
import React, {FC, useEffect, useState} from 'react'
import {useNavigation} from '@react-navigation/core'
import CircleButton from '../../ui/primitive/components/CircleButton'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Dimensions from '../../constants/Layout'
import {timeFormatter} from '../../utils/time'
import priceTransform from '../../utils/priceTransform'

const {isSmallDevice, window} = Dimensions

const OrderConfirmed: FC<IOrderConfirmed> = ({onClose, total, visible}) => {
  const navigation = useNavigation()
  const [orderId, setOrderId] = useState('0')
  const [date] = useState(timeFormatter(new Date()))
  const [topIndent, setTopIndent] = useState(620)

  const payNow = () => {
    onClose()
    navigation.navigate('PayNow')
  }

  const payLater = () => {
    onClose()
  }

  useEffect(() => {
    AsyncStorage.getItem('@last_active_order_id').then((id) => {
      setOrderId(id)
    })
    setTopIndent(getTopIndent())
  }, [])

  const getTopIndent = () => {
    const indent = (window.height / 100) * 80
    if (indent < 620) {
      return 630
    } else {
      return +indent.toFixed(0)
    }
  }

  const styles = useStyleSheet(themedStyles)
  return (
    <Modal
      visible={visible}
      animationType={'fade'}>
      <ScrollView style={{flex: 1}}>
      <View style={[styles.wrapper]}>
        <CircleButton
          style={styles.closeButton}
          iconElement={<MyIcon name="close-outline" fill="#333333" />}
          onClick={payLater}
        />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>ORDER CONFIRMED</Text>
          <Text style={styles.date}>
            {date.days} {date.month} {date.years}
          </Text>
          <Text style={styles.time}>
            {date.hours}:{date.minutes}
          </Text>
          <Text style={styles.orderNum}>ORDER NR. {orderId}</Text>
        </View>
        <View style={styles.deliveryContainer}>
          <Text style={styles.deliveryTitle}>Delivery Information</Text>
          <View style={styles.separator} />
          <View style={styles.deliveryList}>
            <View style={styles.deliveryListItem}>
              <MyIcon
                style={styles.icon}
                fill={'#0500FF'}
                name={'checkmark-circle-2'}
              />
              <Text style={styles.deliveryListText}>Order recived</Text>
            </View>
            <View style={styles.deliveryListItem}>
              <MyIcon
                style={styles.icon}
                fill={'#16192C'}
                name={'clock-outline'}
              />
              <Text style={styles.deliveryListText}>Preparing your order</Text>
            </View>
            <View style={styles.deliveryListItem}>
              <MyIcon
                style={styles.icon}
                fill={'#E9E9E9'}
                name={'alert-circle'}
              />
              <Text style={styles.deliveryListText}>Status: Unpaid</Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={[
          styles.buttonsContainer,
          {top: topIndent},
          !isSmallDevice && {paddingHorizontal: 34},
        ]}
      >
        <Button
          onPress={payNow}
          style={[styles.primaryButton, isSmallDevice && {width: '90%'}]}
          type="blue"
        >
          <MyIcon
            style={styles.buttonIcon}
            fill={'#FFFFFF'}
              name={'credit-card-outline'}
          />
          <Text style={styles.buttonText}>Pay Now</Text>
        </Button>
          <Pressable onPress={payLater}>
          <Text style={styles.payLater}>Pay Later</Text>
        </Pressable>
      </View>
      </ScrollView>
    </Modal>
  )
}

interface IOrderConfirmed {
  onClose?: () => void
  total: number
  visible: boolean
}

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'white',
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 15,
    paddingHorizontal: 32,
    alignItems: 'center',
  },
  titleContainer: {
    height: 255,
    width: 311,
    marginTop: 100,
    borderRadius: 8,
    backgroundColor: '#FAFBFB',
    alignItems: 'center',
    marginBottom: 53,
  },
  title: {
    marginTop: 24,
    fontFamily: 'oswald-bold',
    textTransform: 'uppercase',
    fontSize: 24,
    color: '#000000',
  },
  date: {
    marginTop: 12,
    fontFamily: 'inter-regular',
    fontSize: 14,
    color: '#000000',
  },
  time: {
    fontFamily: 'oswald-bold',
    textTransform: 'uppercase',
    fontSize: 80,
    color: '#000000',
  },
  orderNum: {
    marginTop: 16,
    fontFamily: 'inter-regular',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: 2,
    color: '#000000',
  },
  deliveryContainer: {
    alignItems: 'center',
  },
  deliveryTitle: {
    fontFamily: 'oswald-bold',
    textTransform: 'uppercase',
    fontSize: 28,
    color: '#000000',
  },
  separator: {
    marginTop: 8,
    width: '100%',
    height: 1,
    backgroundColor: '#E2E2E2',
  },
  deliveryList: {
    marginTop: 36,
    alignItems: 'flex-start',
  },
  deliveryListItem: {
    marginBottom: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  deliveryListText: {
    marginLeft: 16,
    fontFamily: 'inter-regular',
    fontSize: 14,
    color: '#000000',
  },
  icon: {
    width: 16,
    height: 16,
  },
  buttonsContainer: {
    position: 'absolute',
    display: 'flex',
    top: 620,
    width: '100%',
    alignItems: 'center',
    zIndex: 16,
  },
  primaryButton: {
    borderWidth: 0,
    borderRadius: 50,
    maxWidth: 337,
    width: '100%',
    marginBottom: 24,
  },
  buttonText: {
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    letterSpacing: 2,
    marginLeft: 8,
    marginRight: 85,
    whiteSpace: 'nowrap',
  },
  buttonIcon: {
    width: 20,
    height: 20,
    marginLeft: 85,
  },
  payLater: {
    color: '#0500FF',
    fontFamily: 'inter-bold',
    fontSize: 12,
    letterSpacing: 2,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  closeButton: {
    top: 20,
    right: 24,
    position: 'absolute',
    borderColor: '#FAFBFB',
  },
})

export default OrderConfirmed
