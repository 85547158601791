// FIXME: needs documentation
export function urlParse(inputUrl: string | null = null): {
  path: string
  origin: string
} {
  const url = new URL(inputUrl ? inputUrl : window.location.href)

  const options = url.search
    .trim()
    .replace(/^[?#&]/, '')
    .split('&')
  const retObj = {path: url.pathname.replaceAll('/', ''), origin: url.origin}
  options.forEach((query) => {
    const parseOpt = query.split('=')
    retObj[parseOpt[0]] = parseOpt[1]
  })
  return retObj
}
