import {Image, Text, View} from 'react-native'
import React, {FC} from 'react'

import Button from '../../ui/primitive/components/Button'
import OrderOverviewButtonContainer from '../../ui/layout/components/OrderOverviewButtonContainer'
import {useNavigation} from '@react-navigation/core'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const EmptyCart: FC = () => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.wrapper}>
      <View style={styles.iconWrapper}>
        <Image
          source={require('../../assets/images/empty-cart.png')}
          style={styles.icon}
        />
      </View>
      <OrderOverviewButtonContainer>
        <Button
          status="basic"
          type="secondary"
          onPress={() => navigation.navigate('Collections')}
        >
          <Text style={styles.buttonText}>Product Overview</Text>
        </Button>
      </OrderOverviewButtonContainer>
    </View>
  )
}

export default EmptyCart

const themedStyles = StyleService.create({
  wrapper: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  iconWrapper: {
    marginTop: 60,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: '65%',
    aspectRatio: 1,
  },
  buttonText: {
    color: 'primaryButtonDisable',
    fontWeight: '500',
    fontSize: 18,
  },
})
