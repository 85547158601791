import {View} from 'react-native'
import React, {FC, useEffect} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import LottieView from 'lottie-react-native'

const ConfirmedSplash: FC<IConfirmedSplash> = ({afterSplash = () => {}}) => {
  const styles = useStyleSheet(themedStyles)

  useEffect(() => {
    setTimeout(() => {
      console.log('afterSplash called')
      afterSplash()
    }, 2500)
  }, [])

  return (
    <View style={styles.wrapper}>
      <LottieView
        source={require('./../../assets/animations/success.json')}
        autoPlay
        loop
      />
    </View>
  )
}

interface IConfirmedSplash {
  afterSplash?: () => void
}

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'white',
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 17,
    paddingHorizontal: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default ConfirmedSplash
