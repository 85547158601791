import React, {FC} from 'react'
import {StyleSheet, Text} from 'react-native'

import Button from '../../ui/primitive/components/Button'
import OrderOverviewButtonContainer from '../../ui/layout/components/OrderOverviewButtonContainer'
import Price from '../../ui/patterns/components/Price'

const AddToBasketButton: FC<IAddToBasket> = ({onPress, price}) => {
  return (
    <>
      <OrderOverviewButtonContainer>
        <Button onPress={onPress} style={styles.primaryButton} type="blue">
          <>
            <Text style={styles.buttonText}>Add to Basket</Text>
            {/*<Price style={[styles.buttonText, styles.price]} price={price} />*/}
          </>
        </Button>
      </OrderOverviewButtonContainer>
    </>
  )
}

export default AddToBasketButton

const styles = StyleSheet.create({
  buttonText: {
    fontSize: 17,
    marginLeft: 30,
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
  primaryButton: {
    borderWidth: 0,
    borderRadius: 50,
  },
  price: {
    marginRight: 8,
    fontWeight: 'bold',
  },
})

interface IAddToBasket {
  price: number
  onPress: () => void
}
