import {Button, MenuGroup} from '@ui-kitten/components'
import {StyleSheet, Text, View} from 'react-native'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import Product from './Product'

import MyIcon from '../../ui/primitive/components/MyIcon'
import CollectionsWaitProducts from './CollectionsWaitProducts'
import {useCollectionRelatedProducts} from '../../hooks/useCollectionRelatedProducts'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'

const CollectionItem: FC<ICollectionItem> = React.memo(
  ({name, collectionId}) => {
    const [open, setOpen] = useState(false)
    const {products} = useCollectionRelatedProducts(collectionId)
    const {total} = usePayLaterOrders()
    const menuRef = useRef(null)

    useEffect(() => {
      if (open && menuRef.current) {
        menuRef.current.onPress()
      }
    }, [total])

    const ArrowIconClose = useCallback(
      () => (
        <MyIcon
          fill="#C4C4C4"
          style={styles.icon}
          name="arrow-ios-downward-outline"
        />
      ),
      [],
    )

    const ArrowIconOpen = useCallback(
      () => (
        <MyIcon
          fill="#333333"
          style={styles.icon}
          name="arrow-ios-upward-outline"
        />
      ),
      [],
    )

    if (!products || products.length <= 0)
      return <CollectionsWaitProducts name={name} />

    return (
      <MenuGroup
        ref={menuRef}
        title={(props) => (
          <Text style={[props?.style, styles.title]}>{name}</Text>
        )}
        onPress={() => setOpen((prev) => !prev)}
        style={styles.container}
        accessoryRight={open ? ArrowIconOpen : ArrowIconClose}
      >
        {open
          ? products.map((product) => (
              <Product open={open} key={product.id} {...product} />
            ))
          : products.map((product) => (
              <View
                key={product.id}
                style={[
                  styles.aggregate,
                  {height: product.name.length > 22 ? 85 : 69},
                ]}
              />
            ))}
      </MenuGroup>
    )
  },
)

export default CollectionItem

const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    fontSize: 18,
    backgroundColor: '#F8F8F8',
    height: 64,
  },
  collectionImage: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: 18,
    color: 'primaryText',
    textTransform: 'uppercase',
    paddingLeft: 10,
    fontFamily: 'oswald-bold',
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 20,
  },
  aggregate: {
    width: '100%',
    height: 80,
  },
})

interface ICollectionItem {
  name: string
  collectionId: string
}
