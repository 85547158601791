import React, {FC, memo, useMemo} from 'react'
import View from '../../ui/primitive/components/View'
import ReviewProduct, {ReviewProductValue} from './ReviewProduct'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import {useActiveOrder} from '../../hooks/useOrder'

const ReviewProducts: FC = memo(() => {
  const styles = useStyleSheet(themedStyles)
  const {data, productCount} = useActiveOrder()

  const productArray = useMemo(() => {
    const array = []
    for (const [key, value] of Object.entries(data)) {
      array.push({key, value})
    }
    return array
  }, [productCount])

  return (
    <View style={styles.productsContainer}>
      {productArray.map(
        ({key, value}: {key: string; value: ReviewProductValue}) => (
          <ReviewProduct id={key} value={value} key={key} />
        ),
      )}
    </View>
  )
})

export default ReviewProducts

const themedStyles = StyleService.create({
  productsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
})
