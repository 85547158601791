import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import {OrderContext} from './Orders'
import {StyleSheet, Text, View} from 'react-native'
import priceTransform from '../../utils/priceTransform'
import LottieView from 'lottie-react-native'
import {Icon} from '@ui-kitten/components'

const TipsLabel: FC = React.memo(() => {
  const {tips} = useContext(OrderContext)
  const lottieRef = useRef(null)
  const [iconVisible, isIconVisible] = useState(true)

  useEffect(() => {
    if (tips) {
      isIconVisible(false)
      lottieRef.current.anim.play()
      lottieRef.current.anim.onComplete = () => {
        isIconVisible(true)
        lottieRef.current.anim.stop()
      }
    }
  }, [tips])

  return (
    <View style={styles.tipLabelContainer}>
      <Text style={styles.tipTitle}>TIPS</Text>
      <View style={styles.tipPrice}>
        <Text style={styles.tipLAbelText}>CHF {priceTransform(tips)}</Text>
        {iconVisible && (
          <Icon
            style={styles.passiveIcon}
            name={'heart'}
            fill={'#000000'}
          ></Icon>
        )}
        <LottieView
          ref={lottieRef}
          style={[styles.icon, iconVisible && {width: 0}]}
          source={require('./../../assets/animations/tip_heart.json')}
        />
      </View>
    </View>
  )
})

export default TipsLabel

const styles = StyleSheet.create({
  tipLabelContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 10,
    marginBottom: 30,
  },
  animation: {
    width: 16,
    height: 16,
  },
  tipPrice: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tipTitle: {
    fontSize: 12,
    color: '#262626',
    fontFamily: 'inter-bold',
  },
  tipLAbelText: {
    fontSize: 12,
    color: '#262626',
    fontFamily: 'inter-bold',
    marginRight: 13,
    wordSpacing: 8,
    width: 90,
    letterSpacing: 2,
  },
  icon: {
    marginRight: 12,
    width: 34,
    height: 34,
  },
  passiveIcon: {
    marginRight: 12,
    width: 16,
    height: 14,
  },
})
