import View from '../../ui/primitive/components/View'
import {StyleService, Text, useStyleSheet} from '@ui-kitten/components'
import {useActiveOrder} from '../../hooks/useOrder'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import priceTransform from '../../utils/priceTransform'
import Input from '../../ui/patterns/components/Input'
import MyIcon from '../../ui/primitive/components/MyIcon'
import CircleButton from '../../ui/primitive/components/CircleButton'
import OrderConfirmed from '../orderConfirmed/OrderConfirmed'
import useSetPayLaterStatus from '../../hooks/useSetPayLaterStatus'
import {Modal, Pressable, ScrollView} from 'react-native'
import {getTableId} from '../../utils/getTable'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PaymentButton from '../../ui/patterns/components/PaymentButton'
import WaitingScreen from './WaitingScreen'
import ConfirmedSplash from '../orderConfirmed/ConfirmedSplash'
import {uniqueNamesGenerator, Config, starWars} from 'unique-names-generator'
import ReviewProducts from './ReviewProducts'

const ReviewOrder: FC<IReviewOrder> = ({visible, onClose}) => {
  const styles = useStyleSheet(themedStyles)
  const {productCount, id, totalWithTax} = useActiveOrder()
  const [handler] = useSetPayLaterStatus()
  const [name, setName] = useState('')
  const [tableId, setTableId] = useState('1')
  const [isError, setIsError] = useState(true)
  const [isFirstVisit, setFirstVisit] = useState(true)
  const [lastTotal, setLastTotal] = useState(0)
  const [waitOpen, setWaitOpen] = useState(false)
  const [splash, setSplash] = useState(false)
  const [orderConfirmed, setOrderConfirmed] = useState(false)

  // useEffect(() => {
  //   if (!productCount) {
  //     console.log("productCount = 0")
  //     //onClose()
  //   }
  // }, [productCount])

  useEffect(() => {
    getTableId().then(setTableId)
    AsyncStorage.getItem('@client_name').then((clientName) => {
      if (clientName) {
        setIsError(false)
        setFirstVisit(false)
        setName(clientName)
      }
    })
  }, [setFirstVisit])

  useEffect(() => {
    if (id) AsyncStorage.setItem('@last_active_order_id', id)
    // if (!lastTotal) {
      setLastTotal(totalWithTax)
    // }
  }, [id, lastTotal, totalWithTax])

  const closeAll = useCallback(() => {
    console.log("closeAll")
    setWaitOpen(false)
    setSplash(false)
    setOrderConfirmed(false)
  }, [setWaitOpen, setSplash, setOrderConfirmed])

  //SUBMIT ORDER button
  const completeOrder = useCallback(() => {
    console.log("completeOrder")
    if (isFirstVisit) {
      console.log("isFirstVisit")
      setFirstVisit(false)
      setIsError(true)
      return
    }
    AsyncStorage.setItem('@client_name', name)
    setWaitOpen(true)
  }, [isFirstVisit, setFirstVisit, setIsError, setWaitOpen])

  //PLACE ORDER button
  const placeOrder = async () => {
    console.log("placeOrder")
    return await handler({customerName: name})
  }

  //after PLACE ORDER button
  const afterWait = useCallback(() => {
    //closeAll()
    console.log("afterWait")
    setWaitOpen(false)
    setSplash(true)
  }, [setWaitOpen, setSplash])

  const afterSplash = useCallback(() => {
    console.log("afterSplash")
    setOrderConfirmed(true)
    setSplash(false)
    //closeAll()
  }, [setOrderConfirmed])

  const inputChange = useCallback(
    (text) => {
      setName(text)
      setFirstVisit(false)
      setIsError(text.length < 1 || text.length > 50)
    },
    [setName, setFirstVisit, setIsError],
  )

  const errorStatus = useMemo(() => {
    if (isFirstVisit) return false
    return isError
  }, [isFirstVisit, isError])

  const generateName = useCallback(() => {
    const config: Config = {
      dictionaries: [starWars],
    }
    inputChange(uniqueNamesGenerator(config))
  }, [inputChange])

  const confirmedCloseHandler = useCallback(() => {
    setOrderConfirmed(false)
    onClose()
  }, [onClose, setOrderConfirmed])

  return (
    <Modal visible={visible} animationType={'slide'}>
      <OrderConfirmed
        visible={orderConfirmed}
        total={lastTotal}
        onClose={confirmedCloseHandler}
      />
      {splash && <ConfirmedSplash afterSplash={afterSplash} />}
      <WaitingScreen
        visible={waitOpen}
        placeOrder={placeOrder}
        goBack={closeAll}
        afterWait={afterWait}
      />
      <ScrollView style={[styles.wrapper]}>
        <CircleButton
          style={styles.closeButton}
          iconElement={<MyIcon name="close-outline" fill="#333333" />}
          onClick={onClose}
        />
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>Review Order</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.contentTitleWrapper}>
            <Text style={styles.containerTitle}>order for Table {tableId}</Text>
            <Text style={styles.containerSubTitle}>
              Please confirm and submit your order. Table delivery usally takes
              5-10 min.
            </Text>
          </View>
          <View>
            <ReviewProducts />
            <View style={styles.subtotalContainer}>
              <View style={styles.subtotal}>
                <Text style={styles.subtotalTitle}>Total</Text>
                <Text style={styles.subtotalPrice}>
                  CHF {priceTransform(totalWithTax)}
                </Text>
              </View>
              <View style={styles.mwstContainer}>
                <Text style={styles.mwst}>Total inkl. MwSt.</Text>
              </View>
            </View>
          </View>
          <View style={styles.nameInputContainer}>
            <Text style={styles.nameInputTitle}>What’s your name?</Text>
            <Text style={styles.nameInputSubtitle}>
              Please tell us your name, so we can find you.
            </Text>
            <Input
              style={styles.nameInput}
              value={name}
              onChangeText={inputChange}
              placeholder={'Name'}
              isError={errorStatus}
              errorText={'Your Name is requried, that we can submit the order.'}
            />
            <Pressable onPress={generateName}>
              <Text style={styles.generateName}>
                Generate a fake name instead 😎
              </Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
      <View style={[styles.buttonContainer, {bottom: 40}]}>
        <PaymentButton
          text={'Submit order ' + priceTransform(totalWithTax)}
          disabled={errorStatus}
          onClick={completeOrder}
        />
      </View>
    </Modal>
  )
}

interface IReviewOrder {
  onClose: () => void
  visible: boolean
}

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'white',
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  closeButton: {
    top: 20,
    right: 24,
    position: 'absolute',
    borderColor: '#FAFBFB',
  },
  titleWrapper: {
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#FAFBFB',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333333',
  },
  container: {
    paddingTop: 32,
    paddingHorizontal: 24,
    marginBottom: 140,
  },
  contentTitleWrapper: {
    marginBottom: 40,
  },
  containerTitle: {
    textTransform: 'uppercase',
    fontSize: 24,
    color: '#262626',
    fontFamily: 'oswald-bold',
    marginBottom: 8,
  },
  containerSubTitle: {
    color: '#4B4B4B',
    fontSize: 14,
    fontFamily: 'inter-medium',
  },
  productsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  subtotalContainer: {
    marginLeft: 50,
    marginRight: 1,
  },
  subtotalTitle: {
    fontFamily: 'inter-bold',
    fontSize: 18,
    color: '#333333',
  },
  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtotalPrice: {
    fontFamily: 'inter-bold',
    fontSize: 18,
    color: '#333333',
    wordSpacing: 18,
  },
  mwst: {
    marginTop: 5,
    fontFamily: 'inter-regular',
    color: '#333333',
  },
  mwstContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    color: '#333333',
  },
  nameInputContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
  nameInputTitle: {
    fontSize: 24,
    color: '#333333',
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
    marginBottom: 8,
  },
  nameInputSubtitle: {
    fontSize: 14,
    fontFamily: 'inter-regular',
    marginBottom: 16,
    color: '#333333',
  },
  nameInput: {
    height: 32,
  },
  generateName: {
    marginTop: 15,
    margin: 'auto',
    textDecoration: 'underline',
    color: '#333333',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    zIndex: 10,
    position: 'absolute',
  },
})

export default ReviewOrder
