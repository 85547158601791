import {gql, useApolloClient, useMutation} from '@apollo/client'
import {GET_ACTIVE_ORDER, ORDER_FRAGMENT} from './useOrder'
import {
  SetOrderLineQuantity,
  SetOrderLineQuantityVariables,
} from './types/SetOrderLineQuantity'
import {getActiveOrder} from './types/getActiveOrder'
import {useCallback} from 'react'

const SET_ORDER_LINE_QUANTITY = gql`
  mutation SetOrderLineQuantity($orderLineId: ID!, $quantity: Int!) {
    adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
      __typename
      ... on Order {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`
export const useSetItemToOrder = () => {
  const apolloClient = useApolloClient()
  const [setQuantityMutation] = useMutation<
    SetOrderLineQuantity,
    SetOrderLineQuantityVariables
  >(SET_ORDER_LINE_QUANTITY, {
    refetchQueries: [{query: GET_ACTIVE_ORDER}],
  })

  return useCallback(
    async (orderLineId: string, quantity: number) => {
      try {
        apolloClient.cache.updateQuery(
          {query: GET_ACTIVE_ORDER},
          (data: getActiveOrder) => ({
            ...data,
            activeOrder: {
              ...data.activeOrder,
              lines: data.activeOrder.lines.map((el) =>
                el.id === orderLineId ? {...el, quantity} : el,
              ),
            },
          }),
        )
        await setQuantityMutation({variables: {orderLineId, quantity}})
      } catch (e) {
        console.error(e)
      }
    },
    [apolloClient.cache, setQuantityMutation],
  )
}
