import gql from 'graphql-tag'
import {useLazyQuery} from '@apollo/client'

import {useEffect} from 'react'
import {getTableId} from '../utils/getTable'
import {
  getPayLaterOrdersByCustomer,
  getPayLaterOrdersByCustomerVariables,
} from './types/getPayLaterOrdersByCustomer'

export const PAY_LATER_ORDERS = gql`
  query getPayLaterOrdersByCustomer($tableId: String!) {
    getPayLaterOrdersByTableId(tableId: $tableId) {
      id
      code
      payments {
        id
        method
        transactionId
      }
      customer {
        id
      }
      totalWithTax
      customFields {
        tableId
        customerName
      }
    }
  }
`

export const usePayLaterOrders = () => {
  const [getLaterOrders, {loading, data}] = useLazyQuery<
    getPayLaterOrdersByCustomer,
    getPayLaterOrdersByCustomerVariables
  >(PAY_LATER_ORDERS)
  const orders = data?.getPayLaterOrdersByTableId
  // FIXME: don't use useLazyQuery mixed with useEffect
  // this can simply be a normal useQuery with variables

  useEffect(() => {
    async function load() {
      const tableId = await getTableId()

      if (!data && !loading && tableId !== undefined) {
        getLaterOrders({
          variables: {tableId: tableId},
        }).then((r) => r)
      }
    }

    load()
  }, [data, getLaterOrders, loading])

  if (!orders)
    return {
      payLaterOrders: [],
      total: 0,
    }
  let total = 0

  const payLaterOrders: Array<{
    id: string
    code: string
    totalWithTax: number
    customerName: string
  }> = []

  orders.forEach((order, index) => {
    total = total + order.totalWithTax
    // FIXME: no mapping needed, just reuse the same type
    payLaterOrders[index] = {
      id: order.id,
      code: order.code,
      totalWithTax: order.totalWithTax,
      customerName: order.customFields.customerName,
    }
  })
  return {payLaterOrders, total}
}
