import {gql, useQuery} from '@apollo/client'
import {useMemo} from 'react'
import {
  GetCollectionWithVariants,
  GetCollectionWithVariantsVariables,
  GetCollectionWithVariants_collection_productVariants_items,
  GetCollectionWithVariants_collection_productVariants_items_product,
} from './types/GetCollectionWithVariants'
import {PRODUCT_BASE_FRAGMENT, PRODUCT_VARIANT_FRAGMENT} from './useProducts'

const QUERY = gql`
  query GetCollectionWithVariants($collectionId: ID!) {
    collection(id: $collectionId) {
      productVariants {
        items {
          ...ProductVariantFragment

          product {
            ...ProductBaseFragment
          }
        }
      }
    }
  }
  ${PRODUCT_VARIANT_FRAGMENT}
  ${PRODUCT_BASE_FRAGMENT}
`

type Grouped = {
  [
    id: string
  ]: GetCollectionWithVariants_collection_productVariants_items_product & {
    variants: Array<
      Omit<
        GetCollectionWithVariants_collection_productVariants_items,
        'product'
      >
    >
  }
}

export const useCollectionRelatedProducts = (collectionId: string) => {
  const {data, ...rest} = useQuery<
    GetCollectionWithVariants,
    GetCollectionWithVariantsVariables
  >(QUERY, {variables: {collectionId}})

  // unfortunatly, the order of products and variants is odd on vendure,
  // see https://github.com/vendure-ecommerce/vendure/issues/1954

  // so we regroup here
  // we also filter out out-of-stock variants
  const products = useMemo(
    () =>
      Object.values(
        data?.collection?.productVariants.items.reduce<Grouped>(
          (acc, {product, ...variant}) => {
            if (variant.stockLevel === 'OUT_OF_STOCK') return acc
            if (!acc[product.id]) {
              acc[product.id] = {
                ...product,
                variants: [],
              }
            }
            acc[product.id].variants.push(variant)
            return acc
          },
          {},
        ) ?? {},
      ),
    [data?.collection?.productVariants.items],
  )
  return {
    ...rest,
    products,
  }
}
