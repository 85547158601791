import View from '../../ui/primitive/components/View'
import {Pressable, Text} from 'react-native'
import MyIcon from '../../ui/primitive/components/MyIcon'
import {removeTags} from '../../utils/removeTags'
import React, {useState} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import {useProducts} from '../../hooks/useProducts'

// FIXME: missing types
const LaterOrder = ({order, index}) => {
  const styles = useStyleSheet(themedStyles)
  const productsList = useProducts()
  const [open, setOpen] = useState(false)

  const getProductsNames = (products) => {
    let productsNames = products.reduce((names: string, product) => {
      if (names) {
        return `${names}, ${product.name}`
      } else {
        return product.name
      }
    }, '')
    if (productsNames.length > 23) {
      productsNames = productsNames.substring(0, 24)
    }
    return productsNames
  }

  const findProductById = (findId: string) => {
    return productsList.find(({id}) => findId === id)
  }

  return (
    <View key={order.id} style={[styles.order]}>
      <View style={styles.orderWrapper}>
        <Text style={styles.title}>
          Order #{index + 2}:{' '}
          <Text style={styles.shortOrdersNames}>
            ({getProductsNames(order.data)}...)
          </Text>
        </Text>
        <Text style={{marginBottom: 5, fontSize: 16}}>Pay Later</Text>
        <Pressable onPress={() => setOpen(!open)} style={styles.iconContainer}>
          {!open ? (
            <MyIcon fill="#333333" name="arrow-circle-down-outline" />
          ) : (
            <MyIcon fill="#333333" name="arrow-circle-up-outline" />
          )}
        </Pressable>
      </View>
      {open && (
        <View style={styles.productsContainer}>
          {order.data.map((product, index) => {
            const findProduct = findProductById(product.productId)

            if (!findProduct) return null

            return (
              <View style={styles.product} key={index}>
                <Text style={{fontSize: 16}}>
                  {findProduct.name} x {product.quantity}
                </Text>
                <Text>{removeTags(findProduct.description)}</Text>
              </View>
            )
          })}
        </View>
      )}
    </View>
  )
}

export default LaterOrder

const themedStyles = StyleService.create({
  productsContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: 12,
  },
  product: {
    display: 'flex',
    paddingVertical: 4,
    borderTopWidth: 1,
    borderTopColor: 'borderColorPrimary',
    marginBottom: 5,
  },
  order: {
    paddingLeft: 24,
    paddingRight: 9,
    paddingTop: 14,
    backgroundColor: 'backgroundSecondary',
    marginBottom: 10,
  },
  orderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'primaryText',
    marginBottom: 6,
  },
  shortOrdersNames: {
    fontWeight: 'normal',
  },
  iconContainer: {
    position: 'absolute',
    right: 10,
    top: 4,
  },
})
