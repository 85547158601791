import * as React from 'react'

import {Image, StyleSheet, View} from 'react-native'

import Colors from '../../constants/Colors'
import Head from '../../ui/layout/components/Head'
import SeeOurMenuButton from '../../ui/layout/components/SeeOurMenuButton'

export default function NotFoundScreen() {
  return (
    <View style={styles.wrapper}>
      <Head
        title="Error 404"
        description="Ooops! you weren't supposed to see this"
      />
      <View style={styles.content}>
        <View style={styles.iconWrapper}>
          <Image
            style={styles.icon}
            source={require('../../assets/images/404.png')}
          />
        </View>
        <SeeOurMenuButton />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#FAFBFB',
    paddingTop: 50,
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: 'space-between',
  },
  iconWrapper: {
    marginTop: 40,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: '85%',
    aspectRatio: 1,
  },
})
