import * as React from 'react'
import Svg, {Path} from 'react-native-svg'

const PaymentErrorSvg = (props) => (
  <Svg
    width={83}
    height={73}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M27.242 41.725H7.38c-.478 0-.866-.344-.866-.769 0-.425.388-.769.866-.769h19.863c.478 0 .866.344.866.77 0 .424-.388.768-.867.768ZM27.242 48.342H7.38c-.478 0-.866-.344-.866-.77 0-.424.388-.768.866-.768h19.863c.478 0 .866.344.866.769 0 .425-.388.77-.867.77ZM41.618 15.28c-.478 0-.866-.345-.866-.77v-2.066c0-.425.388-.77.866-.77.479 0 .867.345.867.77v2.066c0 .425-.388.77-.867.77ZM47.133 17.222c-.741.024-1.165-.857-.612-1.313l1.649-1.461c.338-.3.887-.3 1.225 0a.71.71 0 0 1-.001 1.088l-1.649 1.461a.922.922 0 0 1-.612.225ZM36.096 17.223a.922.922 0 0 1-.612-.225l-1.649-1.462a.71.71 0 0 1 0-1.087.945.945 0 0 1 1.225-.001l1.648 1.461c.553.457.13 1.337-.612 1.313ZM41.618 67.774c-.478 0-.866-.344-.866-.769V64.94c0-.425.388-.77.866-.77.479 0 .867.345.867.77v2.066c0 .425-.388.77-.867.77ZM48.782 65.226A.922.922 0 0 1 48.17 65l-1.65-1.461a.71.71 0 0 1 0-1.088.945.945 0 0 1 1.225-.001l1.649 1.461c.553.457.129 1.337-.612 1.314ZM34.448 65.226c-.742.023-1.166-.857-.613-1.314l1.65-1.46c.338-.3.886-.3 1.224 0a.71.71 0 0 1 0 1.088L35.058 65a.922.922 0 0 1-.611.225ZM67.728 63.88l.275-8.34h-2.52l.275 8.34h1.97ZM66.742 65.341c-.825 0-1.448.57-1.448 1.327 0 .769.609 1.327 1.448 1.327.84 0 1.448-.558 1.448-1.327 0-.781-.595-1.327-1.448-1.327Z"
      fill="#000"
    />
    <Path
      d="m41.472 38.031-5.429-8.494 1.707-6.41c.138-.465-.301-.958-.843-.945H4.614c-2.02 0-3.665 1.458-3.665 3.25v28.585c0 1.792 1.644 3.25 3.665 3.25H36.85c.595.015 1.041-.572.804-1.056l-3.425-7.556 7.215-9.824a.697.697 0 0 0 .028-.8Zm-38.789-12.6c0-.943.867-1.711 1.932-1.711H35.81l-1.532 5.756a.694.694 0 0 0 .09.557l.796 1.246H2.683v-5.847Zm29.848 22.72a.696.696 0 0 0-.078.707l3.114 6.87H4.615c-1.065 0-1.932-.767-1.932-1.711v-21.2h33.465l3.557 5.566-7.174 9.767ZM54.185 57.583c.622 0 1.126-.448 1.126-1 0-.553-.504-1-1.126-1-.622 0-1.126.447-1.126 1 0 .552.504 1 1.126 1Z"
      fill="#000"
    />
    <Path
      d="M81.608 67.66 69.775 48.654c-.136-.219-.299-.418-.482-.596V25.432c0-1.792-1.644-3.25-3.664-3.25H46.234c-.401 0-.75.245-.843.592l-1.785 6.702a.695.695 0 0 0 .09.557l5.336 8.35-7.173 9.767a.695.695 0 0 0-.078.708l3.593 7.927c.132.291.45.482.804.482h3.051c.479 0 .867-.344.867-.77 0-.424-.388-.769-.867-.769h-2.466l-3.206-7.073 7.215-9.824a.697.697 0 0 0 .028-.8l-3.332-5.213h20.093V47.15a3.979 3.979 0 0 0-.819-.085c-1.28 0-2.414.594-3.033 1.588L51.876 67.661c-1.313 1.952.475 4.64 3.034 4.565h23.665c2.559.076 4.346-2.614 3.033-4.565ZM46.485 31.28l-1.114-1.744 1.549-5.816h18.709c1.065 0 1.931.768 1.931 1.712v5.847H46.486Zm32.09 39.407H54.909c-1.28.038-2.173-1.306-1.516-2.282l11.832-19.008c.599-1.047 2.435-1.047 3.034 0L80.09 68.405c.3.481.248 2.283-1.516 2.282ZM75.03 5.52h-1.563c-.479 0-.866-.344-.866-.769 0-.425.387-.769.866-.769h1.562c.479 0 .867.344.867.77 0 .424-.388.769-.867.769ZM66.365 5.52h-6.761c-.479 0-.867-.344-.867-.769 0-.425.388-.769.867-.769h6.76c.48 0 .867.344.867.77 0 .424-.388.769-.866.769ZM81.095 11.674h-1.563c-.478 0-.866-.344-.866-.769 0-.425.388-.769.866-.769h1.563c.478 0 .866.344.866.77 0 .424-.388.768-.866.768ZM74.163 11.674h-8.494c-.478 0-.866-.344-.866-.769 0-.425.388-.769.866-.769h8.494c.479 0 .866.344.866.77 0 .424-.388.768-.866.768ZM9.528 5.52H7.965c-.478 0-.866-.344-.866-.769 0-.425.388-.769.866-.769h1.563c.478 0 .866.344.866.77 0 .424-.388.769-.866.769ZM23.39 5.52h-8.493c-.479 0-.867-.344-.867-.769 0-.425.388-.769.867-.769h8.494c.478 0 .866.344.866.77 0 .424-.388.769-.866.769ZM3.462 11.674H1.9c-.479 0-.867-.344-.867-.769 0-.425.388-.769.867-.769h1.562c.479 0 .867.344.867.77 0 .424-.388.768-.867.768ZM17.325 11.674H8.831c-.478 0-.866-.344-.866-.769 0-.425.388-.769.866-.769h8.494c.479 0 .867.344.867.77 0 .424-.388.768-.867.768Z"
      fill="#000"
    />
  </Svg>
)

export default PaymentErrorSvg
