import React, {FC, useContext, useEffect, useState} from 'react'
import {Pressable, Text, useWindowDimensions, View} from 'react-native'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'
import {OrderContext} from './Orders'
import {Icon, StyleService, useStyleSheet} from '@ui-kitten/components'
import priceTransform from '../../utils/priceTransform'
import {CheckBox} from '@ui-kitten/components'
import AuthModal from './modals/AuthModal'
import OrderDetail from './OrderDetail'

const OrdersList: FC = React.memo(() => {
  const {setTotalPrice, setCheckOrdersIds, checkOrdersIds, totalPrice} =
    useContext(OrderContext)
  const orders = usePayLaterOrders()
  const styles = useStyleSheet(themedStyles)
  const [orderCode, setOrderCode] = useState('')
  const [detailMpdalOpen, setDetailModalOpen] = useState(false)
  const [firstVisit, setFirstVisit] = useState(true)
  const {width} = useWindowDimensions()

  const smallestDevice = width < 356

  useEffect(() => {
    if (orderCode) {
      setDetailModalOpen(true)
    }
  }, [orderCode])

  useEffect(() => {
    if (orders.total && firstVisit) {
      setTotalPrice(orders?.total)
      setFirstVisit(false)
    }
  }, [orders])

  const closeDetailModal = () => {
    setDetailModalOpen(false)
    setOrderCode('')
  }

  const openDetailModal = (code) => {
    setOrderCode(code)
  }

  const onCheck = (id, status, orderPrice) => {
    if (status) {
      setTotalPrice(totalPrice - orderPrice)
    } else {
      setTotalPrice(totalPrice + orderPrice)
    }
    setCheckOrdersIds(status, id)
  }

  return (
    <>
      <View style={styles.wrapper}>
        <Text style={styles.title}>ORDERS</Text>
        <View style={styles.list}>
          {orders?.payLaterOrders?.map((order, index) => {
            return (
              <View key={index} style={styles.orderContainer}>
                <View
                  style={[
                    styles.leftContainer,
                    smallestDevice && {width: '40%'},
                  ]}
                >
                  <View style={styles.checkBoxContainer}>
                    <CheckBox
                      checked={!checkOrdersIds[order.id]}
                      onChange={(status) => {
                        onCheck(order.id, !status, order.totalWithTax)
                      }}
                    />
                  </View>
                  <Text
                    style={styles.header}
                    numberOfLines={1}
                    ellipsizeMode={'clip'}
                  >
                    #{index + 1} {order.customerName}
                  </Text>
                </View>
                <View style={styles.infoContainer}>
                  <Text style={styles.price}>CHF</Text>
                  <Text style={[styles.price, styles.priceValue]}>
                    {priceTransform(order.totalWithTax)}
                  </Text>
                  <Pressable
                    onPress={() => {
                      openDetailModal(order.code)
                    }}
                  >
                    <Icon
                      name={'question-mark-circle-outline'}
                      fill={'#333333'}
                      style={styles.icon}
                    />
                  </Pressable>
                </View>
              </View>
            )
          })}
        </View>
      </View>
      <AuthModal isOpen={detailMpdalOpen} closeHandler={closeDetailModal}>
        <OrderDetail code={orderCode} />
      </AuthModal>
    </>
  )
})

const themedStyles = StyleService.create({
  wrapper: {
    marginTop: 27,
    paddingHorizontal: 24,
  },
  header: {
    fontSize: 12,
    marginLeft: 20,
    color: 'secondary',
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    letterSpacing: 2,
    verticalAlign: 'middle',
    marginTop: 3,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '60%',
  },
  price: {
    fontSize: 12,
    color: 'secondary',
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    letterSpacing: 2,
    wordSpacing: 8,
    textAlign: 'right',
    verticalAlign: 'middle',
    marginTop: 3,
  },
  priceValue: {
    width: 53,
  },
  list: {
    marginTop: 16,
  },
  orderContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingLeft: 24,
    paddingVertical: 20,
    paddingRight: 20,
    borderColor: '#E2E8F0',
    borderWidth: 1,
    marginBottom: 8,
    borderRadius: 10,
    flexDirection: 'row',
    border: 1,
    shadowColor: '#171717',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 15,
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 15,
    fontWeight: '500',
  },
  title: {
    fontSize: 12,
    color: '#262626',
    letterSpacing: 2,
    fontFamily: 'inter-bold',
  },
  checkBoxContainer: {
    marginLeft: 2,
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 16,
    marginTop: 2,
  },
  infoContainer: {
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
  },
})

export default OrdersList
