import AsyncStorage from '@react-native-async-storage/async-storage'

export async function setSplashScreen(logo) {
  if (logo === 'notExist') {
    await AsyncStorage.setItem('@splash_logo', 'notExist')
  } else {
    await AsyncStorage.setItem(
      '@splash_logo',
      process.env.API_URL + '/assets/' + logo,
    )
  }
}
