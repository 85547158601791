import React, {FC} from 'react'
import Button from '../../ui/primitive/components/Button'
import {StyleSheet, Text, useWindowDimensions, View} from 'react-native'
import Price from '../../ui/patterns/components/Price'
import {useActiveOrder} from '../../hooks/useOrder'

const OrderButton: FC<IOrderButton> = ({onPress}) => {
  const {productCount, totalWithTax} = useActiveOrder()
  const {width} = useWindowDimensions()

  const smallestDevice = width < 351

  if (productCount === 0) return null

  return (
    <View style={styles.orderButtonContainer}>
      <Button
        onPress={onPress}
        style={[styles.primaryButton, smallestDevice && {width: '90%'}]}
        type="blue"
      >
        <>
          <View style={styles.productCount}>
            <Text style={styles.productCountText}>{productCount}</Text>
          </View>
          <Text style={[styles.buttonText, styles.buttonTitle]}>
            VIEW ORDER
          </Text>
          <Price
            style={[styles.buttonText, styles.price]}
            price={totalWithTax}
          />
        </>
      </Button>
    </View>
  )
}

export default OrderButton

interface IOrderButton {
  onPress: () => void
}

const styles = StyleSheet.create({
  orderButtonContainer: {
    position: 'absolute',
    display: 'flex',
    bottom: 40,
    width: '100%',
    alignItems: 'center',
  },
  buttonText: {
    flex: 1,
    fontSize: 13,
    fontWeight: '500',
    color: '#ffffff',
    textAlign: 'right',
    letterSpacing: 2,
  },
  buttonTitle: {
    marginLeft: 16,
    textAlign: 'left',
    width: 200,
    whiteSpace: 'nowrap',
    fontSize: 12,
  },
  productCount: {
    width: 32,
    height: 32,
    marginLeft: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 50,
  },
  productCountText: {
    fontWeight: 'bold',
    borderRadius: 50,
    color: '#0500FF',
    fontSize: 16,
    paddingTop: 3,
  },
  primaryButton: {
    borderWidth: 0,
    borderRadius: 50,
  },
  price: {
    marginRight: 8,
    marginLeft: 0,
    fontWeight: 'bold',
    width: 50,
  },
})
