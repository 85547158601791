import {gql, useMutation} from '@apollo/client'

export const CALL_WAITER_ACTION = gql`
  mutation CallWaiter {
    callWaiterAction
  }
`

const useAddCallWaiterAction = () => {
  const [callAction, data] = useMutation(CALL_WAITER_ACTION)

  const handler = async () => {
    const {data} = await callAction()

    return data.callWaiterAction
  }

  return [handler, data] as const
}

export default useAddCallWaiterAction
