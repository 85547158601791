import {Dimensions, Image, Modal, Text, View} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import Button from '../../ui/primitive/components/Button'
import React from 'react'
import hand from '../../assets/icons/successHand.png'

const windowHeight = Dimensions.get('window').height
const InvoiceModal = ({handler, visible}) => {
  const styles = useStyleSheet(themedStyles)

  const okHandler = () => {
    handler()
  }

  return (
    <Modal visible={visible} animationType={'slide'}>
      <View style={[styles.wrapper, {height: windowHeight}]}>
        <View style={styles.backdrop} />
        <View style={styles.card}>
          <Text style={styles.header}>Invoice Sent</Text>
          <Text style={styles.subHeader}>
            We just sent you the invoice per mail. If you need one in paper
            form, please go to the counter or call the waiter.
          </Text>
          <Image source={hand} style={styles.image}></Image>
          <Button type={'blue'} style={styles.button} onPress={okHandler}>
            <Text style={styles.buttonText}>OK, Got it</Text>
          </Button>
        </View>
      </View>
    </Modal>
  )
}

export default InvoiceModal

const themedStyles = StyleService.create({
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 20,
  },
  image: {
    width: 81,
    height: 72,
    marginHorizontal: 'auto',
    marginBottom: 32,
  },
  backdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: '#999999',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  card: {
    width: '80%',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 24,
    display: 'flex',
  },
  header: {
    color: '#333333',
    textTransform: 'uppercase',
    fontSize: 28,
    fontFamily: 'oswald-bold',
    marginBottom: 12,
  },
  subHeader: {
    color: '#333333',
    fontFamily: 'inter-regular',
    fontSize: 14,
    marginBottom: 32,
  },
  button: {
    height: 48,
    width: '100%',
    borderRadius: 28,
    marginBottom: 16,
  },
  buttonText: {
    color: '#FFFFFF',
    fontFamily: 'inter-bold',
    fontSize: 12,
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
})
