import {View} from 'react-native'
import Head from './Head'
import React from 'react'
import {useActiveOrder} from '../../hooks/useOrder'
import EmptyCart from './EmptyCart'
import Cart from './cart'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'

export default function OrderOverviewScreen() {
  const {data} = useActiveOrder()
  const orders = Object.keys(data)
  const payLaterOrders = usePayLaterOrders()
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.wrapper}>
      <Head />
      {!!orders.length || !!payLaterOrders?.length ? <Cart /> : <EmptyCart />}
    </View>
  )
}

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'backgroundSecondary',
    paddingTop: 50,
    height: '100%',
  },
})
