import {detectDevice} from './detectDevice'
import {PaymentProviders} from '../types/global-types'

export const paymentMethods = [
  {
    id: PaymentProviders.APPLE,
    url: require('../assets/icons/newPayment/apple.png'),
    name: 'Apple Pay',
    height: 19,
  },
  {
    id: PaymentProviders.GOOGLE,
    url: require('../assets/icons/newPayment/google.png'),
    height: 19,
    name: 'Google Pay',
  },
  {
    id: PaymentProviders.POST_FINANCE,
    url: require('../assets/icons/newPayment/post.png'),
    name: 'PostFinance Card',
  },
  {
    id: PaymentProviders.TWINT,
    url: require('../assets/icons/newPayment/twint.png'),
    name: 'Twint',
  },
  {
    id: PaymentProviders.VISA,
    url: require('../assets/icons/newPayment/visa.png'),
    name: 'Visa',
  },
  {
    id: PaymentProviders.MASTERCARD,
    url: require('../assets/icons/newPayment/master.png'),
    name: 'Master Card',
  },
]
export const detectDevicePayment = () => {
  let payment = paymentMethods.find(({id}) => id === PaymentProviders.TWINT)

  switch (detectDevice()) {
    case 'android-web': {
      payment = paymentMethods.find(({id}) => id === PaymentProviders.GOOGLE)
      break
    }
    case 'ios-web': {
      payment = paymentMethods.find(({id}) => id === PaymentProviders.APPLE)
      break
    }
    case 'ios':
    case 'macos': {
      payment = paymentMethods.find(({id}) => id === PaymentProviders.APPLE)
      break
    }
    case 'android': {
      payment = paymentMethods.find(({id}) => id === PaymentProviders.GOOGLE)
    }
  }
  return payment
}
