import {Text, View} from 'react-native'
import React from 'react'
import {useNavigation} from '@react-navigation/core'
import Button from '../../ui/primitive/components/Button'
import useOrderNow from '../../hooks/useOrderNow'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const OrderButtons = () => {
  const navigation = useNavigation()
  const orderNow = useOrderNow()
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Button style={styles.orderButton} type="blue" onPress={orderNow}>
        <Text style={styles.buttonText}>Order now</Text>
      </Button>
      <Button
        status="basic"
        type="secondary"
        onPress={() => navigation.navigate('Collections')}
      >
        <Text style={styles.buttonText}>Product Overview</Text>
      </Button>
    </View>
  )
}

export default OrderButtons

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 10,
  },
  orderButton: {
    marginBottom: 12,
  },
  buttonText: {
    color: 'backgroundPrimary',
    fontWeight: '500',
    fontSize: 18,
  },
})
