import {
  Button as DefaultButton,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import React from 'react'
import Colors from '../../../constants/Colors'
import {StyleSheet} from 'react-native'

const themedStyles = StyleService.create({
  container: {
    borderRadius: 0,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 18,
    fontWeight: '500',
    minHeight: 56,
  },
})

export const buttonStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    color: Colors.light.secondaryText,
    fontSize: 18,
  },
  primary: {
    backgroundColor: Colors.light.primaryButton,
    color: Colors.light.secondaryText,
    borderColor: Colors.light.borderColorPrimary,
  },
  secondary: {
    backgroundColor: Colors.light.secondaryButton,
    color: Colors.light.secondaryText,
    borderColor: Colors.light.borderColorSecondary,
  },
  blue: {
    borderWidth: 0,
    backgroundColor: Colors.light.buttonColorBlue,
    color: Colors.light.secondaryText,
  },
})

export const disabledStyles = StyleSheet.create({
  primary: {
    backgroundColor: Colors.light.primaryButtonDisable,
  },
  secondary: {
    backgroundColor: Colors.light.secondaryButtonDisable,
  },
  blue: {
    backgroundColor: '#FFFFFF',
    borderWidth: 2,
    borderColor: '#0500FF',
    borderStyle: 'solid',
  },
})

export type ButtonProps = DefaultButton['props'] & {
  type: 'primary' | 'secondary' | 'blue'
}

const Button: React.FC<ButtonProps> = ({
  style,
  children,
  type,
  disabled,
  ...otherProps
}) => {
  const styles = useStyleSheet(themedStyles)
  const typeStyles =
    type === 'primary'
      ? buttonStyles.primary
      : type === 'secondary'
      ? buttonStyles.secondary
      : buttonStyles.blue

  const disabledStyle =
    disabled &&
    (type === 'primary'
      ? disabledStyles.primary
      : type === 'secondary'
      ? disabledStyles.secondary
      : disabledStyles.blue)

  return (
    <DefaultButton
      {...otherProps}
      disabled={disabled}
      activeOpacity={0.8}
      style={[styles.container, style, typeStyles, disabledStyle]}>
      <>{children}</>
    </DefaultButton>
  )
}

export default Button
