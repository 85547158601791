import {useContext} from 'react'
import {OrderContext} from '../features/payNow/Orders'
import useGateway from './useGateway'
import {
  CurrencyCode,
  CustomPaymentMethodType,
  PaymentProviders,
} from '../types/global-types'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {usePayLaterOrders} from './usePayLaterOrders'
import {getOrderIds} from '../utils/getOrderIds'

const usePayNow = () => {
  const {setError, tips, totalPrice, checkOrdersIds} = useContext(OrderContext)
  const [createGateway] = useGateway()
  const orders = usePayLaterOrders()

  const price = tips + totalPrice

  return async (paymentMethod: PaymentProviders): Promise<void> => {
    try {
      const keys = Object.keys(checkOrdersIds)
      let allOrdersIds = getOrderIds(orders.payLaterOrders)

      keys.map((elem) => {
        if (checkOrdersIds[elem]) {
          allOrdersIds = allOrdersIds.filter((item) => item !== elem)
        }
      })

      await AsyncStorage.setItem('@last_amount', price.toString())
      await AsyncStorage.setItem('@payment_order_ids', allOrdersIds.join())

      const {data: createGatewayData} = await createGateway({
        variables: {
          amount: price,
          idsList: allOrdersIds,
          paymentMethod: CustomPaymentMethodType.PAYREXX,
          currency: CurrencyCode.CHF,
          pm: paymentMethod,
        },
      })

      // unknown error
      if (!createGatewayData?.createGateway) {
        throw new Error()
      }

      const data = createGatewayData.createGateway
      if (data.__typename === 'CreateGatewayError') {
        return setError(data.message)
      }

      if (!data.link) {
        throw new Error()
      }

      await AsyncStorage.setItem('@payment_gateway', JSON.stringify(data))
      await AsyncStorage.setItem('@payment_last_tip', JSON.stringify(tips))
      window.open(data.link, '_self')
    } catch (e) {
      console.log('error', e)
      return setError('Failed to create order, please try again later')
    }
  }
}

export default usePayNow
