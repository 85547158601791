import * as eva from '@eva-design/eva'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import {ApplicationProvider, IconRegistry} from '@ui-kitten/components'
import {EvaIconsPack} from '@ui-kitten/eva-icons'
import Navigation from './navigation'
import React, {useEffect, useState} from 'react'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import appTheme from './constants/Colors'
import useCachedResources from './hooks/useCachedResources'
import useColorScheme from './hooks/useColorScheme'
import {useActiveChannel} from './hooks/useActiveChannel'
import SplashScreen from './ui/layout/components/SplashScreen'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {urlParse} from './utils/subdomainHelpers'
import {getTableId} from './utils/getTable'
import {setSplashScreen} from './utils/getSplashScreen'
import {Helmet, HelmetProvider} from 'react-helmet-async'

const httpLink = (token, tableID) =>
  createHttpLink({
    uri: `${process.env.API_URL}/shop-api`,
    credentials: 'include',
    headers: {
      'vendure-token': token,
      'table-id': tableID,
    },
  })

export const client = (token, tableID) =>
  new ApolloClient({
    link: from([httpLink(token, tableID)]),
    cache: new InMemoryCache(),
    credentials: 'include',
  })

const App = () => {
  const {isLoadingComplete, params, initialPage} = useCachedResources()
  const theme = useColorScheme()
  const {data, loading} = useActiveChannel()
  const [showLogo, setShowLogo] = useState(true)

  useEffect(() => {
    if (!loading && showLogo) {
      if (data?.customFields.logo && initialPage) {
        setTimeout(() => setShowLogo(false), 2000)
      } else {
        setShowLogo(false)
      }
    }

  }, [loading, showLogo, data, initialPage])

  if (!isLoadingComplete || loading) return null

  return (
    <>
      <SplashScreen
        url={data?.customFields.logo}
        showLogo={showLogo}
        color={data?.customFields.colour}
      />
      <SafeAreaProvider>
        <IconRegistry icons={EvaIconsPack} />
        <ApplicationProvider
          {...eva}
          theme={{...appTheme[theme], ...eva[theme]}}>
          <Navigation params={params} />
        </ApplicationProvider>
      </SafeAreaProvider>
    </>
  )
}

const Index = () => {
  const [token, setToken] = useState('')
  const [tableID, setTableId] = useState('')
  useEffect(() => {
    const parseUrl = urlParse()
    const load = async () => {
      try {
          const paymentExist = !!(await AsyncStorage.getItem('@payment_gateway'))

         if((parseUrl.path === 'success-payment' || parseUrl.path === 'payment-error') && paymentExist) {
             const token = await AsyncStorage.getItem('@channel_token')
             setToken(token)
             setTableId(await getTableId())
             return null
         }

        if(parseUrl.path === 'collections' && parseUrl.tableId && parseUrl.channel) {
          if(parseUrl.tableId < 1) {
              window.location.href = '/'
          }
          const resp = await fetch(
              process.env.API_URL +
              '/custom-channels/get-channel-settings?channel=' +
              parseUrl?.channel || 'default',
          )
          const parsed = await resp.json()

          if(parsed.token === 'notExists' || parsed.maxTable < parseUrl.tableId) {
              window.location.href = '/'
          }

          await AsyncStorage.setItem('@channel_token', parsed.token)
          await AsyncStorage.setItem('@channel_name', parseUrl.channel)
          await AsyncStorage.setItem('@splash_colour', parsed.colour)
          await AsyncStorage.setItem('@payment_gateway', '')
          await setSplashScreen(parsed.splashScreen)
          setToken(parsed.token)
          setTableId(parseUrl.tableId)
        } else {
            if(!parseUrl.path.length || parseUrl.path === 'scanner') {
                setToken('default')
            } else {
                window.location.href = '/'
            }
        }
      } catch {
        setToken('')
      }
    }

    load()
  }, [])

  return (
    !!token && (
      <ApolloProvider client={client(token, tableID)}>
        <HelmetProvider>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>
          <App />
        </HelmetProvider>
      </ApolloProvider>
    )
  )
}

export default Index
