import React, { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'

const OrderOverviewButtonContainer: FC<IOrderOverviewButtonContainer> = ({
  children,
  isSticky = false,
}) => {
  return (
    <View style={[styles.wrapper, isSticky && styles.stickyWrapper]}>
      {children}
    </View>
  )
}

export default OrderOverviewButtonContainer

const styles = StyleSheet.create({
  wrapper: {
    borderColor: Colors.light.borderColorPrimary,
    borderTopWidth: 1,
    backgroundColor: Colors.light.backgroundSecondary,
    paddingTop: 24,
    width: '100%',
    paddingHorizontal: 16,
    paddingBottom: 32,
    alignItems: 'center',
  },
  stickyWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
})

interface IOrderOverviewButtonContainer {
  isSticky?: boolean
}
