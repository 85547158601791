import React, {FC} from 'react'
import {Dimensions, View} from 'react-native'
import Orders from './Orders'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const height = Dimensions.get('window').height

const PayNowScreen: FC = () => {
  const styles = useStyleSheet(themedStyles)
  return (
    <View style={[styles.wrapper, {height}]}>
      <Orders />
    </View>
  )
}

export default PayNowScreen

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'backgroundSecondary',
    flex: 1,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
})
