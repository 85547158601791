import { Platform } from 'react-native'

export enum Devices {
  ANDROID_WEB = 'android-web',
  IOS_WEB = 'ios-web',
  PC_WEB = 'pc-web',
  IOS = 'ios',
  MACOS = 'macos',
  WINDOWS = 'windows',
  ANDROID = 'android',
}

export const isiOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isAndroid = () => {
  return navigator.userAgent.includes('Android') && 'ontouchend' in document
}

export const detectDevice = () => {
  switch (Platform.OS) {
    case 'web': {
      if (isAndroid()) {
        return Devices.ANDROID_WEB
      } else if (isiOS()) {
        return Devices.IOS_WEB
      }
      return Devices.PC_WEB
    }
    case 'ios':
      return Devices.IOS
    case 'macos':
      return Devices.MACOS
    case 'windows':
      return Devices.WINDOWS
    case 'android':
      return Devices.ANDROID
  }
}
