import React, {FC, useCallback, useEffect, useState} from 'react'
import Head from '../../ui/layout/components/Head'
import {Pressable, StyleSheet, Text, View, TextInput} from 'react-native'
import Colors from '../../constants/Colors'
import {useNavigation} from '@react-navigation/core'
import {useActiveOrder} from '../../hooks/useOrder'
import {getTableId} from '../../utils/getTable'
import AsyncStorage from '@react-native-async-storage/async-storage'
import priceTransform from '../../utils/priceTransform'
import useAfterSuccessPay from '../../hooks/useAfterSuccessPay'
import CloseButton from '../../ui/patterns/components/CloseButton'
import {timeFormatter} from '../../utils/time'
import PaymentButton from '../../ui/patterns/components/PaymentButton'
import {useSentInvoice} from '../../hooks/useSentInvoice'
import InvoiceModal from '../payNow/InvoiceModal'
import Dimensions from '../../constants/Layout'
import {useActiveChannel} from '../../hooks/useActiveChannel'

const {isSmallDevice, window} = Dimensions

const SuccessPaymentScreen: FC = () => {
  const [tableId, setTableId] = useState('')
  const [emailInput, setEmailInput] = useState('')
  const {loading} = useActiveOrder()
  const [amount, setAmount] = useState('0')
  const navigation = useNavigation()
  const [date] = useState(timeFormatter(new Date()))
  const afterSuccessPay = useAfterSuccessPay()
  const {data: activeChannelDate} = useActiveChannel()
  const [handler] = useSentInvoice()
  const [sentVisible, setSendVisible] = useState(false)
  const [topIndent, setTopIndent] = useState(620)

  useEffect(() => {
    getTableId().then(setTableId)
    // sendNotification().then()
    AsyncStorage.getItem('@last_amount').then((lastAmount) =>
      setAmount(lastAmount),
    )
    setTopIndent(getTopIndent())
  }, [])

  const getTopIndent = () => {
    const indent = (window.height / 100) * 80
    if (indent < 620) {
      return 630
    } else {
      return +indent.toFixed(0)
    }
  }

  useEffect(() => {
    async function load() {
      await afterSuccessPay()
    }
    load()
  }, [])

  const sendInvoice = () => {
    AsyncStorage.getItem('@payment_order_ids').then((keysString) => {
      const keys = keysString.split(',')
      handler(keys, emailInput).then((resp) => {
        if (resp?.data?.sendInvoice === 'Email send') {
          setSendVisible(true)
        }
      })
    })
  }

  const buttonHandler = useCallback(() => {
    setTimeout(async () => {
      try {
        // await afterSuccessPay()
      } finally {
        navigation.navigate('Collections', {
          tableId,
          channel: activeChannelDate.code,
        })
      }
    })
  }, [activeChannelDate, navigation, tableId])

  const invoiceHandler = useCallback(() => {
    setSendVisible(false)
    navigation.navigate('Collections', {
      tableId,
      channel: activeChannelDate.code,
    })
  }, [activeChannelDate, navigation, tableId])

  return (
    <View style={styles.wrapper}>
      {loading ? (
        <Head title="Loading" description="Thanks for your waiting" />
      ) : (
        <>
          <InvoiceModal
            visible={sentVisible}
            handler={invoiceHandler}
          ></InvoiceModal>
          <CloseButton onPress={buttonHandler} />
          <View style={styles.titleContainer}>
            <Text style={styles.title}>THANKS FOR THE PAYMENT</Text>
            <Text style={styles.date}>
              {date.days} {date.month} {date.years} | {date.hours}:
              {date.minutes}
            </Text>
            <Text style={styles.time}>{priceTransform(+amount)}</Text>
            <Text style={styles.orderNum}>PAYMEND CONFIRMED</Text>
          </View>
          <View style={styles.invoice}>
            <Text style={styles.invHeader}>Send Invoice per Email</Text>
            <Text style={styles.invSubHeader}>
              We will send you your invoice per e-mail
            </Text>
            <TextInput
              value={emailInput}
              onChangeText={(text) => {
                setEmailInput(text)
              }}
              placeholder={'Email Address'}
              style={styles.input}
            ></TextInput>
          </View>
          <View
            style={[
              styles.orderButtonContainer,
              {top: topIndent},
              !isSmallDevice && {paddingHorizontal: 34},
            ]}
          >
            <PaymentButton
              disabled={false}
              text={'Send Invoice'}
              visibleIcon={false}
              onClick={sendInvoice}
            ></PaymentButton>
            <Pressable onPress={buttonHandler}>
              <Text style={styles.back}>Back to Menu</Text>
            </Pressable>
          </View>
        </>
      )}
    </View>
  )
}

export default SuccessPaymentScreen

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: 'white',
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 15,
    paddingHorizontal: 32,
    alignItems: 'center',
  },
  buttonText: {
    color: Colors.light.secondaryText,
    fontWeight: '500',
    fontSize: 18,
  },
  invHeader: {
    color: '#333333',
    fontFamily: 'inter-bold',
    fontSize: 16,
    marginBottom: 6,
  },
  invSubHeader: {
    fontFamily: 'inter-regular',
    fontSize: 16,
    color: '#333333',
    marginBottom: 24,
  },
  titleContainer: {
    height: 255,
    width: 311,
    marginTop: 132,
    borderRadius: 8,
    backgroundColor: '#FAFBFB',
    alignItems: 'center',
    marginBottom: 32,
  },
  title: {
    marginTop: 24,
    fontFamily: 'oswald-bold',
    textTransform: 'uppercase',
    fontSize: 24,
  },
  date: {
    marginTop: 12,
    fontFamily: 'inter-regular',
    fontSize: 14,
  },
  time: {
    fontFamily: 'oswald-bold',
    textTransform: 'uppercase',
    fontSize: 80,
  },
  orderNum: {
    marginTop: 16,
    fontFamily: 'inter-regular',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: 2,
  },
  input: {
    height: 56,
    backgroundColor: '#EEEEEE',
    borderRadius: 4,
    paddingHorizontal: 16,
    width: '100%',
  },
  orderButtonContainer: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  primaryButton: {
    borderRadius: 50,
    maxWidth: 337,
    width: '100%',
    marginBottom: 20,
  },
  sendText: {
    fontWeight: 'bold',
    borderRadius: 50,
    color: '#FFFFFF',
    fontSize: 16,
    paddingTop: 3,
  },
  back: {
    textTransform: 'uppercase',
    fontFamily: 'inter-regular',
    fontSize: 12,
    marginTop: 20,
    letterSpacing: 2,
    marginBottom: 20,
  },
  invoice: {
    paddingVertical: 16,
    width: '100%',
  },
  icon: {
    width: 24,
    height: 18,
  },
  invoiceText: {
    marginLeft: 16,
  },
  invoiceHeader: {
    color: '#231F20',
    fontFamily: 'inter-regular',
    fontWeight: '700',
    fontSize: 16,
  },
  invoiceSubHeader: {
    color: '#231F20',
    fontFamily: 'inter-regular',
    fontWeight: '400',
    fontSize: 16,
  },
  keyIcon: {
    width: 19,
    height: 18,
    marginRight: 10,
  },
})
