/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * @description
 * ISO 4217 currency code
 * 
 * @docsCategory common
 */
export enum CurrencyCode {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XCD = "XCD",
  XOF = "XOF",
  XPF = "XPF",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export enum CustomPaymentMethodType {
  PAYREXX = "PAYREXX",
}

export enum ErrorCode {
  ALREADY_LOGGED_IN_ERROR = "ALREADY_LOGGED_IN_ERROR",
  COUPON_CODE_EXPIRED_ERROR = "COUPON_CODE_EXPIRED_ERROR",
  COUPON_CODE_INVALID_ERROR = "COUPON_CODE_INVALID_ERROR",
  COUPON_CODE_LIMIT_ERROR = "COUPON_CODE_LIMIT_ERROR",
  CREATE_GATEWAY_ERROR = "CREATE_GATEWAY_ERROR",
  EMAIL_ADDRESS_CONFLICT_ERROR = "EMAIL_ADDRESS_CONFLICT_ERROR",
  IDENTIFIER_CHANGE_TOKEN_EXPIRED_ERROR = "IDENTIFIER_CHANGE_TOKEN_EXPIRED_ERROR",
  IDENTIFIER_CHANGE_TOKEN_INVALID_ERROR = "IDENTIFIER_CHANGE_TOKEN_INVALID_ERROR",
  INELIGIBLE_PAYMENT_METHOD_ERROR = "INELIGIBLE_PAYMENT_METHOD_ERROR",
  INELIGIBLE_SHIPPING_METHOD_ERROR = "INELIGIBLE_SHIPPING_METHOD_ERROR",
  INSUFFICIENT_STOCK_ERROR = "INSUFFICIENT_STOCK_ERROR",
  INVALID_CREDENTIALS_ERROR = "INVALID_CREDENTIALS_ERROR",
  MISSING_PASSWORD_ERROR = "MISSING_PASSWORD_ERROR",
  NATIVE_AUTH_STRATEGY_ERROR = "NATIVE_AUTH_STRATEGY_ERROR",
  NEGATIVE_QUANTITY_ERROR = "NEGATIVE_QUANTITY_ERROR",
  NOT_VERIFIED_ERROR = "NOT_VERIFIED_ERROR",
  NO_ACTIVE_ORDER_ERROR = "NO_ACTIVE_ORDER_ERROR",
  ORDER_LIMIT_ERROR = "ORDER_LIMIT_ERROR",
  ORDER_MODIFICATION_ERROR = "ORDER_MODIFICATION_ERROR",
  ORDER_PAYMENT_STATE_ERROR = "ORDER_PAYMENT_STATE_ERROR",
  ORDER_STATE_TRANSITION_ERROR = "ORDER_STATE_TRANSITION_ERROR",
  PASSWORD_ALREADY_SET_ERROR = "PASSWORD_ALREADY_SET_ERROR",
  PASSWORD_RESET_TOKEN_EXPIRED_ERROR = "PASSWORD_RESET_TOKEN_EXPIRED_ERROR",
  PASSWORD_RESET_TOKEN_INVALID_ERROR = "PASSWORD_RESET_TOKEN_INVALID_ERROR",
  PASSWORD_VALIDATION_ERROR = "PASSWORD_VALIDATION_ERROR",
  PAYMENT_DECLINED_ERROR = "PAYMENT_DECLINED_ERROR",
  PAYMENT_FAILED_ERROR = "PAYMENT_FAILED_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  VERIFICATION_TOKEN_EXPIRED_ERROR = "VERIFICATION_TOKEN_EXPIRED_ERROR",
  VERIFICATION_TOKEN_INVALID_ERROR = "VERIFICATION_TOKEN_INVALID_ERROR",
}

export enum LogicalOperator {
  AND = "AND",
  OR = "OR",
}

export enum PaymentProviders {
  APPLE = "APPLE",
  E_POST_FINANCE = "E_POST_FINANCE",
  GOOGLE = "GOOGLE",
  MASTERCARD = "MASTERCARD",
  POST_FINANCE = "POST_FINANCE",
  TWINT = "TWINT",
  VISA = "VISA",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface CollectionFilterParameter {
  id?: IDOperators | null;
  createdAt?: DateOperators | null;
  updatedAt?: DateOperators | null;
  languageCode?: StringOperators | null;
  name?: StringOperators | null;
  slug?: StringOperators | null;
  position?: NumberOperators | null;
  description?: StringOperators | null;
}

export interface CollectionListOptions {
  skip?: number | null;
  take?: number | null;
  sort?: CollectionSortParameter | null;
  filter?: CollectionFilterParameter | null;
  filterOperator?: LogicalOperator | null;
}

export interface CollectionSortParameter {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  slug?: SortOrder | null;
  position?: SortOrder | null;
  description?: SortOrder | null;
}

/**
 * Operators for filtering on a DateTime field
 */
export interface DateOperators {
  eq?: any | null;
  before?: any | null;
  after?: any | null;
  between?: DateRange | null;
  isNull?: boolean | null;
}

export interface DateRange {
  start: any;
  end: any;
}

/**
 * Operators for filtering on an ID field
 */
export interface IDOperators {
  eq?: string | null;
  notEq?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  isNull?: boolean | null;
}

/**
 * Operators for filtering on a Int or Float field
 */
export interface NumberOperators {
  eq?: number | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  between?: NumberRange | null;
  isNull?: boolean | null;
}

export interface NumberRange {
  start: number;
  end: number;
}

/**
 * Operators for filtering on a String field
 */
export interface StringOperators {
  eq?: string | null;
  notEq?: string | null;
  contains?: string | null;
  notContains?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  regex?: string | null;
  isNull?: boolean | null;
}

export interface UpdateOrderCustomFieldsInput {
  orderPaymentGateway?: string | null;
  tableId?: string | null;
  customerName?: string | null;
  waiterPayment?: boolean | null;
  acceptedOrder?: boolean | null;
  waiterCalling?: boolean | null;
  createTime?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
