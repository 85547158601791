import * as React from 'react'

import {Text, View} from 'react-native'

import {FC} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const Head: FC = () => {
  const styles = useStyleSheet(themedStyles)
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.title}>Done</Text>
      <View style={styles.line} />
      <Text style={styles.tableTitle}>Order is on it's way</Text>
    </View>
  )
}

export default Head

const themedStyles = StyleService.create({
  titleContainer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
  title: {
    fontSize: 45,
    fontWeight: 'bold',
    color: 'primary',
  },
  line: {
    width: '100%',
    height: 5,
    backgroundColor: 'primary',
  },
  tableTitle: {
    marginTop: 24,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'secondary',
  },
})
