import {useCallback, useEffect, useRef} from 'react'
import {Animated} from 'react-native'

const useAnimation = (dep: boolean): Animated.Value => {
  const widthAnim = useRef(new Animated.Value(0)).current

  const fadeIn = useCallback(() => {
    Animated.timing(widthAnim, {
      useNativeDriver: false,
      toValue: 40,
      duration: 300,
    }).start()
  }, [widthAnim])

  const fadeOut = useCallback(() => {
    Animated.timing(widthAnim, {
      useNativeDriver: false,
      toValue: 0,
      duration: 200,
    }).start()
  }, [widthAnim])

  useEffect(() => {
    if (dep) {
      fadeIn()
    } else {
      fadeOut()
    }
  }, [dep, fadeIn, fadeOut])

  return widthAnim
}

export default useAnimation
