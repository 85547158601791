import {Modal, Text, View} from 'react-native'
import React, {FC, memo} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import {compose, map, reduce} from 'ramda'

import AddToBasketButton from '../../../features/collections/AddToBasket'
import CloseButton from './CloseButton'
import Product from '../../../features/collections/Product'
import {Products_products_items_variants} from '../../../hooks/types/Products'
import {useActiveOrder} from '../../../hooks/useOrder'

const ProductVariantsModal: FC<IProductVariant> = memo(
  ({variants, productName, description, open, onClose}) => {
    const {data} = useActiveOrder()
    const styles = useStyleSheet(themedStyles)

    if (!open) return null

    const pricesForVariants = compose(
      map((variant: Products_products_items_variants) => {
        const productOrder = data[variant.id]
        if (!productOrder) {
          return 0
        }

        return productOrder.quantity * variant.price
      }),
    )(variants)
    const price = reduce((acc, item) => acc + item, 0, pricesForVariants)

    return (
      <Modal animationType="fade" hardwareAccelerated onRequestClose={onClose}>
        <View style={styles.wrapper}>
          <CloseButton onPress={onClose} />
          <View style={styles.paddingHorizontal}>
            <Text style={styles.title}>{productName}</Text>
            <Text style={styles.description}>{description}</Text>
            <Text style={styles.optionTitle}>Variants of {productName}</Text>
          </View>
          {variants.map((variant) => (
            <Product
              key={variant.id}
              open
              id={variant.id}
              name={variant.name}
              description={''}
              variants={[variant]}
            />
          ))}

          {price > 0 && <AddToBasketButton onPress={onClose} price={price} />}
        </View>
      </Modal>
    )
  },
)

export default ProductVariantsModal

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: '#FAFBFB',
    paddingTop: 50,
    height: '100%',
  },
  paddingHorizontal: {
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'primary',
  },
  description: {
    lineHeight: 24,
    fontSize: 15,
    color: 'grey',
  },
  optionTitle: {
    marginTop: 24,
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'secondary',
  },
  price: {
    marginLeft: 'auto',
  },
  radio: {
    margin: 0,
    padding: 18,
    backgroundColor: 'greyBackground',
  },
  variantName: {
    marginLeft: 8,
  },
})

interface IProductVariant {
  open: boolean
  productName: string
  productId: string
  description: string
  onClose: () => void
  variants: Products_products_items_variants[]
}
