import React, {FC, useContext} from 'react'
import {OrderContext} from '../Orders'
import priceTransform from '../../../utils/priceTransform'
import PaymentButton from '../../../ui/patterns/components/PaymentButton'

const PayNowButton: FC<IPayNowButton> = React.memo(({payNowHandler}) => {
  const {tips, totalPrice} = useContext(OrderContext)

  const onPress = () => {
    payNowHandler()
  }

  return (
    <PaymentButton
      disabled={!totalPrice}
      onClick={onPress}
      text={'PAY NOW ' + priceTransform(tips + totalPrice)}
    ></PaymentButton>
  )
})

export default PayNowButton

interface IPayNowButton {
  disabled?: boolean
  payNowHandler: () => void
}
