import {Button} from '@ui-kitten/components'
import {StyleSheet} from 'react-native'
import React, {FC, memo} from 'react'

import Colors from '../../../constants/Colors'
import {useSetItemToOrder} from '../../../hooks/useSetItemToOrder'
import MyIcon from '../../primitive/components/MyIcon'

const MinusButton: FC<IMinusButton> = memo(
  ({disabled, orderLineId, quantity, fill}) => {
    const setOrderLineQuantity = useSetItemToOrder()

    const MinusIcon = () => (
      <MyIcon
        fill={fill || '#0500FF'}
        style={styles.icon}
        name="minus-circle-outline"
      />
    )

    return (
      <Button
        style={[
          styles.button,
          disabled ? {backgroundColor: Colors.light.backgroundSecondary} : {},
        ]}
        appearance="ghost"
        disabled={disabled}
        onPress={() =>
          !disabled && setOrderLineQuantity(orderLineId, quantity - 1)
        }
        accessoryRight={MinusIcon}
      />
    )
  },
)

export default MinusButton

const styles = StyleSheet.create({
  button: {
    width: 24,
    height: 24,
    paddingRight: 20,
  },
  icon: {
    width: 24,
    height: 24,
  },
})

interface IMinusButton {
  orderLineId: string
  disabled: boolean
  quantity: number
  fill?: string
}
