import {View} from 'react-native'
import MinusButton from '../../ui/patterns/components/MinusButton'
import AddButton from '../../ui/patterns/components/AddButton'
import React, {FC, memo} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const ProductButtons: FC<IProductButtons> = memo(
  ({quantity, orderLineId, disabled, firstVariantId}) => {
    const styles = useStyleSheet(themedStyles)

    return (
      <View style={styles.buttons}>
        {quantity > 0 && orderLineId && (
          <>
            <MinusButton
              disabled={disabled}
              orderLineId={orderLineId}
              quantity={quantity}
            />
            <View style={styles.separator} />
            <AddButton disabled={disabled} productId={firstVariantId} />
          </>
        )}
      </View>
    )
  },
)

export default ProductButtons

const themedStyles = StyleService.create({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    position: 'relative',
    width: 100,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 10,
  },
  separator: {
    height: 53,
    width: 1,
    backgroundColor: '#C4C4C4',
    position: 'absolute',
    left: '50%',
    top: -5,
  },
})

interface IProductButtons {
  quantity: number
  orderLineId: string
  disabled: boolean
  firstVariantId: string
}
