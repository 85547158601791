import {View} from 'react-native'
import Text from '../../ui/primitive/components/Text'
import QuestButton from '../../ui/patterns/components/QuestButton'
import React, {FC, memo} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import Price from '../../ui/patterns/components/Price'

const ProductContent: FC<IProductContent> = memo(
  ({name, descriptionText, detailsHandler, price}) => {
    const styles = useStyleSheet(themedStyles)

    return (
      <View style={styles.contentWrapper}>
        <View style={styles.headContainer}>
          <Text numberOfLines={2} ellipsizeMode={'clip'} style={styles.title}>
            {name}
          </Text>
          {!!descriptionText && (
            <QuestButton onPress={detailsHandler}></QuestButton>
          )}
        </View>
        <Price price={price} />
      </View>
    )
  },
)

export default ProductContent

const themedStyles = StyleService.create({
  headContainer: {
    flexDirection: 'row',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 4,
    color: 'primaryText',
    maxWidth: 200,
    wordSpacing: 3,
  },
  contentWrapper: {
    marginRight: 'auto',
    justifyContent: 'center',
    flex: 1,
    minHeight: 40,
  },
})

interface IProductContent {
  name: string
  descriptionText: string
  detailsHandler: () => void
  price: number
}
