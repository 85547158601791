import {
  Dimensions,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
} from 'react-native'
import {paymentMethods} from '../../utils/detectDevicePayment'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import React, {useContext, useState} from 'react'
import {OrderContext} from './Orders'
import priceTransform from '../../utils/priceTransform'
import check from '../../assets/icons/checkbox/checked.png'
import unCheck from '../../assets/icons/checkbox/unChecked.png'
import PaymentButton from '../../ui/patterns/components/PaymentButton'
import PayCashModal from './PayCashModal'

const windowWidth = Dimensions.get('window').width

const PaymentMethodSideMenu = ({
  onSelect,
  activePayment,
  onPress,
  payCash,
  closeHandler,
}: IPaymentMethodSideMenu) => {
  const styles = useStyleSheet(themedStyles)
  const {tips, totalPrice} = useContext(OrderContext)
  const [waiterVisible, setWaiterVisible] = useState(false)

  const payCashHandler = () => {
    payCash()
    setWaiterVisible(true)
  }

  return (
    <>
      <PayCashModal
        visible={waiterVisible}
        handler={closeHandler}
      ></PayCashModal>
      <View style={[styles.head, {width: windowWidth}]}>
        <Text style={styles.headTitle}>Pay Bill</Text>
      </View>
      <Text style={styles.selectTitle}>Payment Method</Text>
      <ScrollView style={styles.paymentsContainer}>
        {paymentMethods.map(({id, url, name}) => (
          <Pressable
            style={styles.selectItem}
            key={id}
            onPress={() => {
              onSelect(id)
            }}
          >
            <View style={styles.selectItemLeft}>
              <Image source={{uri: url}} style={[styles.selectImage]} />
              <Text style={styles.selectItemLeftText}>{name}</Text>
            </View>
            {activePayment.id === id ? (
              <Image source={check} style={[styles.checkImage]} />
            ) : (
              <Image source={unCheck} style={[styles.checkImage]} />
            )}
          </Pressable>
        ))}
      </ScrollView>
      <View style={[styles.buttons, {width: windowWidth}]}>
        <PaymentButton
          onClick={onPress}
          disabled={false}
          text={'PAY NOW ' + priceTransform(tips + totalPrice)}
        />
        <Pressable onPress={payCashHandler}>
          <Text style={styles.cash}>Pay CASH</Text>
        </Pressable>
      </View>
    </>
  )
}

export default PaymentMethodSideMenu

const themedStyles = StyleService.create({
  paymentsContainer: {
    height: 100,
    marginBottom: 140,
    paddingBottom: 10,
  },
  selectImage: {
    width: 48,
    height: 48,
    marginRight: 16,
  },
  buttons: {
    backgroundColor: '#FAFBFB',
    zIndex: 10,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: 37,
    paddingTop: 12,
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#EEEEEE',
    paddingHorizontal: 24,
  },
  head: {
    backgroundColor: '#FAFBFB',
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE',
  },
  headTitle: {
    fontFamily: 'inter-bold',
    fontSize: 16,
  },
  selectItem: {
    backgroundColor: 'backgroundPrimary',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 13,
    marginBottom: 7,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E2E8F0',
    borderRadius: 12,
    marginHorizontal: 24,
  },
  selectItemLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 16,
    maxWidth: '70%',
  },
  selectItemLeftText: {
    fontFamily: 'inter-bold',
    fontSize: 18,
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
  },
  selectTitle: {
    fontSize: 28,
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
    marginBottom: 24,
    marginTop: 14,
    color: '#262626',
    paddingHorizontal: 24,
  },
  cash: {
    color: '#0500FF',
    marginTop: 20,
    textTransform: 'uppercase',
    fontFamily: 'inter-bold',
    textDecoration: 'underline',
  },
  checkImage: {
    width: 24,
    height: 24,
  },
})

export interface IPaymentMethodSideMenu {
  onSelect: (id) => void
  activePayment: (typeof paymentMethods)[number]
  onPress: () => void
  payCash: () => void
  closeHandler: () => void
}
