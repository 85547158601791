/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking'

import {LinkingOptions} from '@react-navigation/native'
import {RootStackParamList} from '../types'

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Scanner: 'scanner',
      Collections: 'collections',
      OrderOverview: 'order-overview',
      OrderDone: 'order-done',
      PayNow: 'pay-now',
      SuccessPayment: 'success-payment',
      HangOn: 'hang-on',
      BarService: 'bar-service',
      Break: 'break',
      Error: 'error',
      SplashScreen: 'splash-screen',
      PaymentError: 'payment-error',
      NotFound: '*',
    },
  },
}

export default linking
