import {useNavigation} from '@react-navigation/core'
import {useEffect} from 'react'
import {ChannelStates, useActiveChannel} from './useActiveChannel'

const useInitialNavigation = () => {
  const navigation = useNavigation()
  const {data, loading} = useActiveChannel()
  const state = data?.customFields?.channelState as ChannelStates

  useEffect(() => {
    async function load(): Promise<void> {
      if (state && !loading) {
        if (state === ChannelStates.ORDER_AT_THE_BAR) {
          navigation.navigate('BarService')
        } else if (state === ChannelStates.TABLE_SERVICE) {
          navigation.navigate('TableService')
        }
      }
    }

    load()
  }, [navigation, state, loading])
}

export default useInitialNavigation
