import React, {FC, useState} from 'react'
import usePayNow from '../../../hooks/usePayNow'
import PayNowButton from './PayNowButton'
import OrderOverviewButtonContainer from '../../../ui/layout/components/OrderOverviewButtonContainer'
import AuthModal from '../modals/AuthModal'
import PaymentMethodSideMenu from '../PaymentMetodSidemenu'
import {
  detectDevicePayment,
  paymentMethods,
} from '../../../utils/detectDevicePayment'
import useSetWaitWaiter from '../../../hooks/useSetWaitWaiter'

const Buttons: FC = () => {
  const [disable, setDisable] = useState(false)
  const payNow = usePayNow()
  const [paymentSelectOpen, setPaymentSelectOpen] = useState(false)
  const [activePayment, setActivePayment] = useState(() =>
    detectDevicePayment(),
  )
  const [handler] = useSetWaitWaiter()

  const payCash = () => {
    handler()
  }

  const closeHandler = () => {
    closeSelector()
  }

  const closeSelector = () => {
    setPaymentSelectOpen(false)
  }

  const openSelector = () => {
    setPaymentSelectOpen(true)
  }

  const payNowHandler = async () => {
    if (disable) return
    setDisable(true)
    await payNow(activePayment.id)
    setDisable(false)
  }

  const set = (sId) => {
    const activePM = paymentMethods.find(({id}) => id === sId)
    setActivePayment(Object.assign({}, activePM))
  }

  return (
    <>
      <OrderOverviewButtonContainer isSticky={true}>
        <PayNowButton disabled={false} payNowHandler={openSelector} />
      </OrderOverviewButtonContainer>
      <AuthModal isOpen={paymentSelectOpen} closeHandler={closeSelector}>
        <PaymentMethodSideMenu
          onSelect={set}
          payCash={payCash}
          activePayment={activePayment}
          onPress={payNowHandler}
          closeHandler={closeHandler}
        />
      </AuthModal>
    </>
  )
}

export default Buttons
