import { StyleService, useStyleSheet } from '@ui-kitten/components'

import { View as DefaultView } from 'react-native'
import React from 'react'

const themedStyles = StyleService.create({
  container: {},
})

export type ViewProps = DefaultView['props']

const View: React.FC<ViewProps> = ({ style, ...otherProps }) => {
  const styles = useStyleSheet(themedStyles)

  return <DefaultView {...otherProps} style={[styles.container, style]} />
}

export default View
