import React, {FC} from 'react'
import {Image, StyleSheet, View} from 'react-native'
import Head from '../../ui/layout/components/Head'
import Colors from '../../constants/Colors'
import SeeOurMenuButton from '../../ui/layout/components/SeeOurMenuButton'

const BarServiceScreen: FC = () => {
  return (
    <View style={styles.wrapper}>
      <Head title="Bar service" description="Today please order at the bar" />
      <View style={styles.content}>
        <View style={styles.iconWrapper}>
          <Image
            style={styles.icon}
            source={require('../../assets/images/barService.png')}
          />
        </View>
        <SeeOurMenuButton />
      </View>
    </View>
  )
}

export default BarServiceScreen

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#FAFBFB',
    paddingTop: 50,
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: 'space-between',
  },
  iconWrapper: {
    marginTop: 40,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: '85%',
    aspectRatio: 1,
  },
})
