import React, {FC} from 'react'
import {useActiveOrder} from '../../../hooks/useOrder'
import {Text, View} from 'react-native'
import Price from '../../../ui/patterns/components/Price'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const Total: FC = React.memo(() => {
  const {totalWithTax} = useActiveOrder()
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.wrapper}>
      <Text style={styles.text}>Total</Text>
      <Price style={styles.price} price={totalWithTax} />
    </View>
  )
})

export default Total

const themedStyles = StyleService.create({
  wrapper: {
    marginTop: 24,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 3,
    borderBottomColor: 'primary',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  price: {
    fontWeight: '500',
    fontSize: 15,
  },
})
