import { Text, TextStyle } from 'react-native'

import React from 'react'
import priceTransform from '../../../utils/priceTransform'

const Price = ({ price, style, skipCoins }: IPrice) => {
  return <Text style={style}>CHF {priceTransform(price, skipCoins)}</Text>
}

export default Price

interface IPrice {
  price: number
  style?: TextStyle | TextStyle[]
  skipCoins?: boolean
}
