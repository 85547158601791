import React, { FC } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Colors from '../../../constants/Colors'

const Head: FC<IHead> = ({ title, description }) => {
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.line} />
      <Text style={styles.tableTitle}>{description}</Text>
    </View>
  )
}

export default Head

interface IHead {
  title: string
  description: string
}

const styles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
    paddingTop: 40,
    borderBottomWidth: 1,
    borderBottomColor: '#EDEFF3',
  },
  title: {
    fontSize: 45,
    fontWeight: 'bold',
    color: Colors.light.primary,
  },
  line: {
    width: '100%',
    height: 5,
    backgroundColor: Colors.light.primary,
  },
  tableTitle: {
    marginTop: 24,
    fontSize: 18,
    fontWeight: 'bold',
    color: Colors.light.secondary,
  },
})
