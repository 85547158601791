import {Pressable, Text, View} from 'react-native'
import NumericInput from '../../ui/patterns/components/NumericInput'
import React, {useContext, useEffect, useState} from 'react'
import {OrderContext} from './Orders'
import {
  ActiveTips,
  tipCutter,
  tipsHelpFunc,
  tipsObjectsArray,
} from '../../utils/tipHelper'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const TipButtonsList = React.memo(() => {
  const {setTips, totalPrice} = useContext(OrderContext)
  const [activeTip, setActiveTip] = useState<ActiveTips | null>(null)
  const defaultTips = tipsHelpFunc(tipsObjectsArray, totalPrice)
  const styles = useStyleSheet(themedStyles)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (defaultTips && activeTip) {
      setTips(defaultTips.find(({id}) => activeTip === id).value)
    }
  }, [totalPrice])

  const youtTipSetHandler = () => {
    setTips(0)
    setActiveTip(ActiveTips.YOUR_TIP)
  }

  const yourTipFocus = () => {
    setInputValue('')
  }

  const yourTipChange = (tip: string) => {
    setInputValue(tip)
  }

  const yourTipSubmit = () => {
    const tip = inputValue.replace(',', '.')
    if (isNaN(+tip)) {
      setTips(0)
      setInputValue('')
      setActiveTip(null)
    } else {
      setInputValue(tip)
      setTips(+tipCutter(+tip * 100))
    }
  }

  const tipsHandler = (activeId: ActiveTips) => {
    if (activeTip === activeId) {
      setTips(0)
      setActiveTip(null)
    } else {
      setTips(defaultTips.find(({id}) => activeId === id).value)
      setActiveTip(activeId)
    }
  }

  return (
    <View style={styles.container}>
      <View style={[styles.defTipContainer, styles.card]}>
        <View style={styles.separator} />
        {defaultTips.map(({label, id, style}) => (
          <Pressable
            key={id}
            style={[styles.item, activeTip === id && styles.activeItem, style]}
            onPress={() => tipsHandler(id)}
          >
            <Text style={[styles.text]}>{label}</Text>
          </Pressable>
        ))}
        <Pressable
          style={[
            styles.item,
            activeTip === ActiveTips.YOUR_TIP && styles.activeItem,
            {right: 53},
          ]}
          onPress={youtTipSetHandler}
        >
          <Text style={[styles.text, styles.freeTipText]}>Free Tips</Text>
        </Pressable>
      </View>
      {activeTip === ActiveTips.YOUR_TIP && (
        <View style={[styles.tipInputContainer, styles.card]}>
          <NumericInput
            styleWrapper={[styles.numericInput]}
            value={inputValue}
            onChangeText={yourTipChange}
            onSubmitEditing={yourTipSubmit}
            onFocus={yourTipFocus}
            style={styles.input}
            onBlur={yourTipSubmit}
          />
        </View>
      )}
    </View>
  )
})

export default TipButtonsList

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
  },
  input: {
    fontSize: 16,
    fontFamily: 'inter-bold',
  },
  card: {
    border: 1,
    borderColor: '#E2E8F0',
    shadowColor: '#171717',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 15,
    borderRadius: 8,
  },
  tipInputContainer: {
    marginLeft: 12,
    height: 63,
    width: 67,
    padding: 16,
  },
  defTipContainer: {
    flexDirection: 'row',
    paddingHorizontal: 53,
    paddingTop: 24,
    paddingBottom: 39,
    flexGrow: 1,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#333333',
  },
  text: {
    fontSize: 12,
    fontFamily: 'inter-bold',
    position: 'absolute',
    top: 25,
    width: 50,
    textAlign: 'center',
  },
  freeTipText: {
    width: 60,
  },
  list: {
    marginTop: 8,
    paddingBottom: 13,
  },
  numericInput: {
    marginRight: 0,
    width: 35,
  },
  item: {
    position: 'absolute',
    top: 16,
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: '#333333',
    display: 'flex',
    alignItems: 'center',
  },
  activeItem: {
    backgroundColor: 'blue',
  },
})
