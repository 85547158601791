import {gql, useQuery} from '@apollo/client'
import {IData, ORDER_FRAGMENT} from './useOrder'

export const GET_ORDER = gql`
  query getOrder($code: String!) {
    orderByCode(code: $code) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`

export const useOrderByCode = (code: string) => {
  const {data, loading, refetch} = useQuery(GET_ORDER, {
    variables: {code},
  })

  let activeOrderProducts: IData = {}
  data?.orderByCode?.lines.forEach((item) => {
    activeOrderProducts[item.productVariant.id] = {
      orderLineId: item.id,
      linePriceWithTax: item.linePriceWithTax,
      name: item.productVariant.name,
      productId: item.productVariant.productId,
      productVariantId: item.productVariant.id,
      quantity: item.quantity,
    }
  })

  return {
    data: activeOrderProducts,
    id: data?.orderByCode?.id,
    code: data?.orderByCode?.code,
    productCount: data?.orderByCode?.totalQuantity || 0,
    totalWithTax: data?.orderByCode?.totalWithTax || 0,
    state: data?.orderByCode?.state || 'AddingItems',
    customer: data?.activeCustomer,
    gatewayData: data?.orderByCode?.customFields?.orderPaymentGateway,
    loading,
    refetch,
    customFields: data?.orderByCode?.customFields,
    createdAt: data?.orderByCode?.createdAt,
  }
}
