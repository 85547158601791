import * as Linking from 'expo-linking'
import AsyncStorage from '@react-native-async-storage/async-storage'

export async function getTableId(): Promise<string> {
  try {
    const resp = await Linking.parseInitialURLAsync()
    if (resp?.queryParams?.tableId) {
      return resp?.queryParams?.tableId.toString()
    } else {
      return await AsyncStorage.getItem('@table_id')
    }
  } catch (error) {
    console.log(error)
    return '1'
  }
}
