import React, {FC} from 'react'
import Head from '../../ui/layout/components/Head'
import Colors from '../../constants/Colors'
import {Dimensions, Image, StyleSheet, View} from 'react-native'

const height = Dimensions.get('window').height
const BreakScreen: FC = () => {
  return (
    <View style={[styles.wrapper, {height}]}>
      <Head title="Break" description="Today we’re on a break" />
      <View style={styles.content}>
        <View style={styles.iconWrapper}>
          <Image
            style={styles.icon}
            source={require('../../assets/images/break.png')}
          />
        </View>
      </View>
    </View>
  )
}

export default BreakScreen

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#FAFBFB',
    paddingTop: 50,
    justifyContent: 'space-between',
    height: '100%',
    zIndex: 5,
  },
  content: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: 'space-between',
  },
  iconWrapper: {
    marginTop: 40,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: '85%',
    aspectRatio: 1,
  },
})
