import React, {FC, useMemo} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import Colors from '../../constants/Colors'
import {useAnnouncementText} from '../../hooks/useAnnouncementText'

const SubHead: FC = React.memo(() => {
  const {announcement, loading} = useAnnouncementText()

  const text = useMemo(() => {
    if (announcement) {
      if (announcement.length > 180) {
        return announcement.slice(0, 180) + '...'
      } else {
        return announcement
      }
    } else {
      return ''
    }
  }, [announcement])

  if (loading) return null

  return (
    <View style={styles.wrapper}>
      <View style={styles.tableDescriptionWrapper}>
        {!announcement ? (
          <View style={styles.filled} />
        ) : (
          <Text style={styles.tableDescription} numberOfLines={10}>
            {text}
          </Text>
        )}
      </View>
    </View>
  )
})

export default SubHead

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 24,
    paddingBottom: 20,
  },
  tableDescriptionWrapper: {
    marginTop: 4,
  },
  tableDescription: {
    lineHeight: 24,
    fontSize: 16,
    color: Colors.light.primary,
    fontFamily: 'inter-regular',
  },
  filled: {
    height: 0,
    width: '100%',
  },
})
