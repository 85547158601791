import gql from 'graphql-tag'
import {useLazyQuery} from '@apollo/client'

export const INVOICE = gql`
  query SendInvoice($email: String!, $paymentData: String!, $ids: [String!]) {
    sendInvoice(email: $email, paymentData: $paymentData, ids: $ids)
  }
`

export const useSentInvoice = () => {
  const [invoice] = useLazyQuery(INVOICE)

  const handler = async (ids, email, payment = 'payment') => {
    return invoice({variables: {ids, email, paymentData: payment}})
  }

  return [handler] as const
}
