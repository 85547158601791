import {gql, useMutation} from '@apollo/client'
import {PAY_LATER_ORDERS} from './usePayLaterOrders'

export const SET_ORDERS_STATE = gql`
  mutation useSetPayment(
    $orderIdsList: [String!]
    $method: String!
    $tip: Int!
    $metadata: String!
  ) {
    setPaymentAfterSuccess(
      orderIdsList: $orderIdsList
      method: $method
      tip: $tip
      metadata: $metadata
    ) {
      ... on Order {
        id
        customFields {
          orderPaymentGateway
        }
      }
    }
  }
`

export const useSetPayment = () => {
  return useMutation(SET_ORDERS_STATE, {
    refetchQueries: [PAY_LATER_ORDERS],
    awaitRefetchQueries: true,
  })
}
