import React from 'react'
import Head from '../../ui/layout/components/Head'
import {Image, StyleSheet, Text, View} from 'react-native'
import Colors from '../../constants/Colors'
import OrderOverviewButtonContainer from '../../ui/layout/components/OrderOverviewButtonContainer'
import {useNavigation} from '@react-navigation/core'
import Button from '../../ui/primitive/components/Button'

export interface IErrorScreen {
  route: {
    params: {
      message: string
    }
  }
}

const ErrorScreen = ({route}: IErrorScreen) => {
  const navigation = useNavigation()
  const {message} = route.params

  return (
    <View style={styles.wrapper}>
      <Head title="Error" description="Ooops..." />
      <View style={styles.contentWrapper}>
        <View style={styles.iconWrapper}>
          <Image
            source={require('../../assets/images/404.png')}
            style={styles.icon}
          />
          <Text style={styles.text}>{message}</Text>
        </View>
        <OrderOverviewButtonContainer>
          <Button
            type="secondary"
            onPress={() => navigation.navigate('Collections')}
          >
            <Text style={styles.buttonText}>See our Menu</Text>
          </Button>
        </OrderOverviewButtonContainer>
      </View>
    </View>
  )
}

export default ErrorScreen

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Colors.light.backgroundSecondary,
    paddingTop: 50,
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  contentWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  iconWrapper: {
    marginTop: 60,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: '65%',
    aspectRatio: 1,
  },
  buttonText: {
    color: Colors.light.secondaryText,
    fontWeight: '500',
    fontSize: 18,
  },
  text: {
    fontSize: 18,
    paddingHorizontal: 16,
  },
})
