import {Modal} from 'react-native'
import CircleButton from '../../primitive/components/CircleButton'
import MyIcon from '../../primitive/components/MyIcon'
import View from '../../primitive/components/View'
import {StyleService, Text, useStyleSheet} from '@ui-kitten/components'
import React, {memo} from 'react'

type Props = {
  description: string
  title: string
  onClose: () => void
}
const ProductDescriptionModal = memo(({description, title, onClose}: Props) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <Modal animationType="fade" hardwareAccelerated onRequestClose={onClose}>
      <View style={styles.wrapper}>
        <CircleButton
          style={styles.closeButton}
          iconElement={<MyIcon name="close-outline" fill="#333333" />}
          onClick={onClose}
          hasBackground={false}
        />
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>Product Description</Text>
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.descriptionTitle}>{title}</Text>
          <Text style={styles.descriptionText}>{description}</Text>
        </View>
      </View>
    </Modal>
  )
})

export default ProductDescriptionModal

const themedStyles = StyleService.create({
  wrapper: {
    backgroundColor: 'white',
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  contentContainer: {
    paddingHorizontal: 24,
  },
  closeButton: {
    top: 16,
    right: 24,
    position: 'absolute',
    borderColor: '#FAFBFB',
  },
  titleWrapper: {
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#FAFBFB',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333333',
  },
  descriptionTitle: {
    color: '#262626',
    fontSize: 24,
    fontFamily: 'oswald-bold',
    marginTop: 32,
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  descriptionText: {
    color: '#4B4B4B',
    fontFamily: 'inter-regular',
    fontSize: 14,
  },
})
