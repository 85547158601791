import React, {FC} from 'react'
import {StyleSheet, TextInput, View, ViewStyle} from 'react-native'
import Colors from '../../../constants/Colors'
import ErrorShield from '../../svgComponents/ErrorShield'
import {Text} from '@ui-kitten/components'

const Input: FC<ITextInput> = ({
  style,
  styleWrapper,
  value,
  onChangeText,
  placeholder,
  secureTextEntry,
  errorText = 'Error in input',
  isError = false,
}) => {
  return (
    <View style={styles.container}>
      <View
        style={[styles.wrapper, styleWrapper, isError && styles.errorWrapper]}>
        <TextInput
          style={[styles.input, style]}
          onChangeText={onChangeText}
          value={value}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          placeholderTextColor={Colors.light.placeholder}
        />
      </View>
      {isError && (
        <View style={styles.errorContainer}>
          <ErrorShield />
          <Text style={styles.errorText}>{errorText}</Text>
        </View>
      )}
    </View>
  )
}

export default Input

const styles = StyleSheet.create({
  container: {},
  input: {
    flexGrow: 1,
    fontSize: 16,
  },
  wrapper: {
    borderRadius: 4,
    backgroundColor: Colors.light.inputBackground,
    padding: 12,
    borderWidth: 2,
    borderColor: Colors.light.inputBackground,
  },
  errorWrapper: {
    borderColor: '#FF0000',
    backgroundColor: '#FFE5E5',
  },
  errorContainer: {
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorText: {
    fontFamily: 'inter-regular',
    color: '#FF0000',
    fontSize: 12,
    marginLeft: 8,
  },
})

export interface ITextInput {
  style?: ViewStyle | ViewStyle[]
  styleWrapper?: ViewStyle | ViewStyle[]
  value: string
  onChangeText: (prev: string) => void
  placeholder?: string
  secureTextEntry?: boolean
  errorText?: string
  isError?: boolean
}
