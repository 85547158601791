import {Animated, View} from 'react-native'
import {IOrderDataItem, useActiveOrder} from '../../hooks/useOrder'
import {MenuItem, useStyleSheet, StyleService} from '@ui-kitten/components'
import React, {FC, memo, useCallback, useMemo, useState} from 'react'
import {filter, reduce, values} from 'ramda'

import MoreButton from '../../ui/patterns/components/MoreButton'
import ProductVariantsModal from '../../ui/patterns/components/ProductVariantsModal'
import {Products_products_items_variants} from '../../hooks/types/Products'
import useAnimation from '../../hooks/useAnimation'
import {removeTags} from '../../utils/removeTags'
import Text from '../../ui/primitive/components/Text'
import {useAddItemToOrder} from '../../hooks/useAddItemToOrder'
import ProductDescriptionModal from '../../ui/patterns/components/PdoductDescriptionModal'
import ProductButtons from './ProductButtons'
import ProductContent from './ProductContent'

const Product: FC<IProduct> = memo(
  ({id, name, description, variants, open}) => {
    // FIXME: these are way too many hooks and logic here, which can lead to performance problems, because of tons of re-renders
    const {data} = useActiveOrder()
    const [descriptionOpen, setDescriptionOpen] = useState(false)
    const [openVariants, setOpenVariants] = useState(false)

    const styles = useStyleSheet(themedStyles)

    const descriptionText = useMemo(
      () => removeTags(description),
      [description],
    )
    // FIXME: this needs an explanation
    const rootProductInOrder = useMemo(
      () =>
        reduce(
          (sumQuantity, {quantity, orderLineId}) => ({
            ...sumQuantity,
            orderLineId,
            quantity: sumQuantity.quantity + quantity,
          }),
          ORDER_ACTIVE_ITEM_MOCK,
          filter((item: IOrderDataItem) => item.productId === id)(values(data)),
        ),
      [data, id],
    )

    const firstVariant = variants[0]
    const isRootProduct = !!rootProductInOrder.orderLineId

    const orderActiveItem =
      (isRootProduct && rootProductInOrder) ||
      data[firstVariant?.id] ||
      ORDER_ACTIVE_ITEM_MOCK

    const widthAnim = useAnimation(orderActiveItem.quantity > 0)
    const addItemToOrder = useAddItemToOrder()
    const wrapperPadding = orderActiveItem.quantity > 0 ? 40 : 23

    const clickHandler = useCallback(() => {
      if (variants.length > 1) {
        setOpenVariants(true)
      } else {
        addItemToOrder(firstVariant.id, 1)
      }
    }, [variants.length, firstVariant.id, addItemToOrder])

    const detailsHandler = useCallback(() => {
      setDescriptionOpen(true)
    }, [])

    const closeDescriptionHandler = useCallback(() => {
      setDescriptionOpen(false)
    }, [])

    const closeVariants = useCallback(() => setOpenVariants(false), [])
    if (!firstVariant || firstVariant.stockLevel === 'OUT_OF_STOCK') return null

    return (
      <>
        <View style={[styles.row, {paddingLeft: wrapperPadding}]}>
          {open && ( // this fixes a bug with animation in the menu
            <Animated.View style={[styles.quantityWrapper, {width: widthAnim}]}>
              <Text style={styles.quantity}>{orderActiveItem.quantity}</Text>
            </Animated.View>
          )}
          <MenuItem
            style={[
              styles.product,
              orderActiveItem.quantity > 0 && {marginLeft: 8},
            ]}
            onPress={clickHandler}
            title={
              <>
                <ProductContent
                  name={name}
                  descriptionText={descriptionText}
                  detailsHandler={detailsHandler}
                  price={firstVariant.priceWithTax}
                />
                {variants.length >= 2 && (
                  <View style={[styles.buttons, styles.variantButton]}>
                    <MoreButton onPress={() => setOpenVariants(true)} />
                  </View>
                )}
                {variants.length < 2 && (
                  <ProductButtons
                    orderLineId={orderActiveItem.orderLineId}
                    quantity={orderActiveItem.quantity}
                    disabled={variants.length > 1}
                    firstVariantId={firstVariant.id}
                  />
                )}
              </>
            }
          />
        </View>
        {descriptionOpen && (
          <ProductDescriptionModal
            description={descriptionText}
            title={name}
            onClose={closeDescriptionHandler}
          />
        )}
        <ProductVariantsModal
          productName={name}
          description={descriptionText}
          variants={variants}
          open={openVariants}
          productId={id}
          onClose={closeVariants}
        />
      </>
    )
  },
)

export default Product

interface IOrderActiveItemMock {
  quantity: number
  orderLineId: string
}

// FIXME: get rid of this
const ORDER_ACTIVE_ITEM_MOCK: IOrderActiveItemMock = {
  quantity: 0,
  orderLineId: '',
}

const themedStyles = StyleService.create({
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
    backgroundColor: 'background',
    position: 'relative',
  },
  variantButton: {
    marginRight: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  product: {
    flex: 1,
    paddingRight: 9,
    paddingLeft: 3,
    paddingVertical: 12,
    justifyContent: 'space-between',
    backgroundColor: 'background',
    borderColor: 'background',
  },
  quantityWrapper: {
    backgroundColor: 'primary',
    width: 40,
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  quantity: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
  },

  price: {
    fontSize: 14,
    marginTop: 2,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    position: 'relative',
    width: 100,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 10,
  },
  infoIcon: {
    marginRight: 5,
  },
})

interface IProduct {
  id: string
  name: string
  description: string
  variants: Products_products_items_variants[]
  open: boolean
}
