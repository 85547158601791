import {gql, useMutation} from '@apollo/client'

export const SET_ORDER_CUSTOM_FIELD = gql`
  mutation SetOrderCustomFields($customFields: UpdateOrderCustomFieldsInput!) {
    setOrderCustomFields(input: {customFields: $customFields}) {
      ... on Order {
        id
        customFields {
          orderPaymentGateway
        }
      }
    }
  }
`

const useSetOrderCustomFields = () => {
  return useMutation(SET_ORDER_CUSTOM_FIELD)
}

export default useSetOrderCustomFields
