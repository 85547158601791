import React, {FC} from 'react'
import {Text} from 'react-native'
import View from '../../ui/primitive/components/View'
import {usePayLaterOrders} from '../../hooks/usePayLaterOrders'
import {useActiveOrder} from '../../hooks/useOrder'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import LaterOrder from './Product'

const MyOrders: FC = () => {
  const {payLaterOrders} = usePayLaterOrders()
  const {customFields} = useActiveOrder()
  const styles = useStyleSheet(themedStyles)

  if (!payLaterOrders || !payLaterOrders.length) return null

  return (
    <View style={styles.wrapper}>
      <Text style={[styles.title, styles.generalTitle]}>
        Open orders on table {customFields?.tableId}
      </Text>
      {payLaterOrders.map((order, index) => {
        return <LaterOrder key={index} order={order} index={index} />
      })}
    </View>
  )
}
export default MyOrders

const themedStyles = StyleService.create({
  generalTitle: {
    fontSize: 21,
    paddingLeft: 21,
    paddingTop: 20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'primaryText',
    marginBottom: 6,
  },
  wrapper: {
    backgroundColor: 'backgroundPrimary',
  },
})
