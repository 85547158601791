import {Text, View} from 'react-native'
import React from 'react'
import {useNavigation} from '@react-navigation/core'
import OrderOverviewButtonContainer from '../../ui/layout/components/OrderOverviewButtonContainer'
import Button from '../../ui/primitive/components/Button'
import {StyleService, useStyleSheet} from '@ui-kitten/components'

const OrderButtons = () => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyles)

  return (
    <OrderOverviewButtonContainer>
      <View style={styles.container}>
        <Button
          type="primary"
          style={styles.orderButton}
          onPress={() => navigation.navigate('Collections')}
        >
          <Text style={[styles.buttonText, styles.blackText]}>Order more</Text>
        </Button>
        <Button type="secondary" onPress={() => navigation.navigate('PayNow')}>
          <Text style={styles.buttonText}>Pay already now</Text>
        </Button>
      </View>
    </OrderOverviewButtonContainer>
  )
}

export default OrderButtons

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 10,
  },
  orderButton: {
    marginBottom: 12,
  },
  buttonText: {
    color: 'secondaryText',
    fontWeight: '500',
    fontSize: 18,
  },
  blackText: {
    color: 'primaryText',
  },
})
