import {StyleSheet, View, Image} from 'react-native'
import React, {useEffect, useState} from 'react'
import Colors from '../../constants/Colors'
import {FC} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

const SplashScreen: FC = () => {
  const [logo, setLogo] = useState('')
  const [colour, setColour] = useState('')

  useEffect(() => {
    const load = async () => {
      setLogo(await AsyncStorage.getItem('@splash_logo'))
      setColour(await AsyncStorage.getItem('@splash_colour'))
    }
    load()
  }, [])

  if (!logo || !colour) return null

  return (
    <View style={[styles.wrapper, {backgroundColor: colour}]}>
      <View style={styles.textContainer}>
        {logo === 'notExist' ? (
          <Image
            style={styles.defaultImage}
            source={require('../../assets/images/auer-co.png')}
          />
        ) : (
          <Image
            style={styles.customImage}
            source={{
              uri: logo,
            }}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Colors.light.splashBackground,
    paddingTop: 50,
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  textContainer: {
    display: 'flex',
    position: 'relative',
    top: '40%',
  },
  defaultImage: {
    width: 234,
    height: 31,
  },
  customImage: {
    width: 234,
    height: 234,
  },
})

export default SplashScreen
