export interface ColorTheme {
  primary: string
  secondary: string
  grey: string
  lightGrey: string
  lightLightGrey: string
  blue: string
  placeholder: string
  greyLineDark: string
  background: string
  backgroundPrimary: string
  backgroundSecondary: string
  borderColorPrimary: string
  borderColorSecondary: string
  disable: string
  tint: string
  tabIconDefault: string
  tabIconSelected: string
  buttonColorBlue: string
  inputBackground: string
  backdropBackground: string
  primaryButton: string
  primaryButtonDisable: string
  secondaryButton: string
  secondaryButtonDisable: string
  primaryText: string
  secondaryText: string
  selectText: string
  closeButton: string
  greyBackground: string
  splashBackground: string
  sideMenuBackground: string
  sideMenuCircle: string
}

const LIGHT_THEME: ColorTheme = {
  primary: '#333333',
  secondary: '#262626',
  grey: '#8f8f8f',
  lightGrey: '#E1E1E1',
  lightLightGrey: '#EEEEEE',
  blue: '#2D04FF',
  placeholder: '#333333',
  greyLineDark: '#C4C4C4',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#fafbfb',
  background: '#ffffff',
  borderColorPrimary: '#d9d9dd',
  borderColorSecondary: '#000000',
  disable: '#bfbfbf',
  tint: '#2f95dc',
  tabIconDefault: '#ccc',
  tabIconSelected: '#2f95dc',
  buttonColorBlue: '#0500FF',
  inputBackground: '#f1f1f1',
  backdropBackground: '#5D5D5D7F',
  primaryButton: '#ffffff',
  primaryButtonDisable: '#ffffff',
  secondaryButton: '#333333',
  secondaryButtonDisable: '#525252',
  primaryText: '#333333',
  secondaryText: '#ffffff',
  selectText: '#142132',
  closeButton: '#F4F4F4',
  greyBackground: '#F7F7F7',
  splashBackground: '#FF005D',
  sideMenuBackground: '#0500FF',
  sideMenuCircle: '#4521FF',
}

const DARK_THEME: ColorTheme = {
  primary: '#333333',
  secondary: '#262626',
  grey: '#8f8f8f',
  lightGrey: '#E1E1E1',
  lightLightGrey: '#EEEEEE',
  blue: '#2D04FF',
  placeholder: '#333333',
  greyLineDark: '#C4C4C4',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#fafbfb',
  background: '#ffffff',
  borderColorPrimary: '#d9d9dd',
  borderColorSecondary: '#000000',
  tint: '#ffffff',
  disable: '#bfbfbf',
  tabIconDefault: '#ccc',
  tabIconSelected: '#ffffff',
  buttonColorBlue: '#0500FF',
  inputBackground: '#f1f1f1',
  backdropBackground: '#5D5D5D7F',
  primaryButton: '#ffffff',
  primaryButtonDisable: '#ffffff',
  secondaryButton: '#333333',
  secondaryButtonDisable: '#525252',
  primaryText: '#333333',
  secondaryText: '#ffffff',
  selectText: '#142132',
  closeButton: '#F4F4F4',
  greyBackground: '#F7F7F7',
  splashBackground: '#FF005D',
  sideMenuBackground: '#0500FF',
  sideMenuCircle: '#4521FF',
}

const Colors = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
}

export default Colors
