import {useOrderByCode} from '../../hooks/useOrderByCode'
import {Dimensions, Text, View} from 'react-native'
import React, {useEffect, useState} from 'react'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import {timeFormatter} from '../../utils/time'
import priceTransform from '../../utils/priceTransform'

const windowWidth = Dimensions.get('window').width

const OrderDetail = ({code}) => {
  const {data, id, customFields, createdAt, totalWithTax} = useOrderByCode(code)
  const styles = useStyleSheet(themedStyles)
  const [date, setDate] = useState(timeFormatter(new Date()))
  const [productArray, setProductArray] = useState([])

  useEffect(() => {
    setDate(timeFormatter(new Date(createdAt)))
  }, [createdAt])

  useEffect(() => {
    const array = []
    let subtotalAccum = 0
    for (const [key, value] of Object.entries(data)) {
      array.push({key, value})
      subtotalAccum = subtotalAccum + value.linePriceWithTax
    }
    setProductArray(array)
  }, [createdAt])

  return (
    <>
      <View style={[styles.head, {width: windowWidth}]}>
        <Text style={styles.headTitle}>Order Details</Text>
      </View>
      <Text style={styles.selectTitle}>
        No. {id} - by {customFields?.customerName}
      </Text>
      <View style={styles.subtitleContainer}>
        <Text style={styles.subtitle}>
          Order date: {date.days}. {date.month} {date.years} - {date.hours}:
          {date.minutes}
        </Text>
        <Text style={styles.subtitle}>
          Bellow you can see the items of this order.
        </Text>
      </View>
      <View style={styles.productContainer}>
        {productArray.map(({key, value}) => (
          <View key={key} style={styles.productItem}>
            <View style={styles.productContentCntainer}>
              <View style={styles.counter}>
                <Text style={styles.counterNumber}>{value.quantity}</Text>
              </View>
              <View style={styles.productDescription}>
                <Text style={styles.productName}>{value.name}</Text>
                <Text style={styles.productPrice}>
                  CHF {priceTransform(value.linePriceWithTax)}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
      <View style={styles.totalWrapper}>
        <View style={styles.totalContainer}>
          <Text style={styles.totalPrice}>
            Total CHF {priceTransform(totalWithTax)}
          </Text>
          <Text style={styles.vat}>(incl. VAT)</Text>
        </View>
      </View>
    </>
  )
}

export default OrderDetail

const themedStyles = StyleService.create({
  container: {
    marginTop: 12,
    zIndex: 1,
    borderWidth: 0,
    borderRadius: 50,
    width: '100%',
  },
  totalWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 37,
    marginTop: 10,
  },
  totalContainer: {
    alignItems: 'flex-end',
  },
  totalPrice: {
    fontFamily: 'inter-bold',
    fontSize: 18,
    color: '#333333',
    wordSpacing: 8,
  },
  vat: {
    fontFamily: 'inter-regular',
    fontSize: 18,
    color: '#333333',
  },
  productContainer: {
    paddingHorizontal: 24,
    marginTop: 20,
  },
  head: {
    backgroundColor: '#FAFBFB',
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE',
  },
  headTitle: {
    fontFamily: 'inter-bold',
    fontSize: 16,
  },
  selectTitle: {
    fontSize: 28,
    textTransform: 'uppercase',
    fontFamily: 'oswald-bold',
    marginBottom: 24,
    marginTop: 14,
    color: '#262626',
    paddingHorizontal: 24,
  },
  subtitleContainer: {
    paddingHorizontal: 24,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'inter-regular',
    color: '#4B4B4B',
  },
  productItem: {
    width: 327,
    height: 64,
    position: 'relative',
    backgroundColor: '#F9F9F9',
    borderRadius: 4,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: '#171717',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  counter: {
    width: 48,
    height: 64,
    backgroundColor: '#333333',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counterNumber: {
    color: '#FFFFFF',
    fontSize: 18,
  },
  productName: {
    color: '#333333',
    fontFamily: 'inter-regular',
    fontSize: 14,
  },
  productPrice: {
    color: '#333333',
    fontFamily: 'inter-bold',
    fontSize: 14,
  },
  productDescription: {
    marginLeft: 12,
  },
  productContentCntainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
