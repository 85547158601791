import React, {useCallback, useState} from 'react'
import {View, Text, Dimensions} from 'react-native'
import {StyleService, useStyleSheet} from '@ui-kitten/components'
import WelcomeModal from './WelcomeModal'
import Scanner from './Scanner'

const windowHeight = Dimensions.get('window').height

const ScannerPage = () => {
  const styles = useStyleSheet(themedStyles)
  const [scannerInactive, setScannerInactive] = useState(true)

  const openScanner = useCallback(() => {
    setScannerInactive(!scannerInactive)
  }, [scannerInactive])

  return (
    <View style={[styles.container, {height: windowHeight}]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {scannerInactive ? 'Welcome To Fonsy.app...' : 'Scan QR-Code now!'}
        </Text>
      </View>
      {scannerInactive ? (
        <WelcomeModal openScanner={openScanner} />
      ) : (
        <Scanner closeScanner={openScanner} />
      )}
      <View style={styles.place}></View>
    </View>
  )
}

export default ScannerPage

const themedStyles = StyleService.create({
  titleContainer: {
    marginBottom: 67,
    width: 295,
  },
  title: {
    fontFamily: 'oswald-bold',
    fontSize: 36,
    color: 'secondaryText',
    textTransform: 'uppercase',
  },
  container: {
    backgroundColor: '#FF005D',
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 40,
    justifyContent: 'center',
  },
  scanner: {
    width: 200,
    height: 200,
  },
  place: {
    height: 106,
    width: '100%',
  },
})
